export const LastPageIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_856_15079)">
      <path d="M5.59 7.41L10.18 12L5.59 16.59L7 18L13 12L7 6L5.59 7.41ZM16 6H18V18H16V6Z" fill="#231F20" />
    </g>
    <defs>
      <clipPath id="clip0_856_15079">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
