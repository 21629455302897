import type { RootState } from '../rootReducer'

export const selectProducts = ({ productReducer }: RootState) => ({
  products: productReducer.productsList,
  total: productReducer.total,
  facetFields: productReducer.facetFields,
})

export const selectCurrentProduct = ({ productReducer }: RootState) => productReducer.currentProduct

export const selectProductInventory = ({ productReducer }: RootState) => productReducer.productInventory

export const plpLoading = ({ productReducer }: RootState) => productReducer.plpLoader

export const selectItemsPerPage = ({ productReducer }: RootState) => productReducer.itemsPerPage

export const selectUploadedImg = ({ productReducer }: RootState) => productReducer.uploadedImg

export const selectProductFilters = ({ productReducer }: RootState) => productReducer.productFilters

export const selectProductListViewMode = ({ productReducer }: RootState) => productReducer.viewMode

export const selectDisplayProductMinimumQtyModal = ({ productReducer }: RootState) => productReducer.displayMinQtyModal
