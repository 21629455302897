import { AsYouType } from 'libphonenumber-js'
import { phone } from 'phone'

import * as AppConfig from '../config'
import { CheckoutAddress, CustomerAddress } from '../types/checkout'

export const formatPhoneNumber = (phoneNumberString: string, code: string | undefined): string | null => {
  if (!code || code === 'CAN' || code === 'USA') return new AsYouType('US').input(phoneNumberString)

  return phone(phoneNumberString, { country: code + '' }).phoneNumber
}

type JwtPayload = {
  exp: number
  given_name?: string
  family_name?: string
  email?: string
  roles?: string[]
  sub?: string
  newUser?: boolean
  imageUrl?: string
  signInName?: string
  extension_company?: string
  domains?: string[]
}

export const parseJwt = (token: string): JwtPayload => {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
        })
        .join('')
    )

    return JSON.parse(jsonPayload) as JwtPayload
  } catch (error) {
    return { exp: 0 }
  }
}

export const isValidJwt = (originToken?: string) => {
  const token = originToken ?? window.localStorage.getItem('token')

  if (!token) {
    return false
  }

  const jwtPayload: { exp: number } = parseJwt(token)
  if (jwtPayload.exp < new Date().getTime() / 1000) {
    return false
  }

  return true
}

export const isQorpak = () => {
  const domain = window.location.hostname
  return domain.indexOf('qorpak.com') > -1
}

export const getLowerEnv = () => {
  const domain = window.location.hostname
  console.log(domain.split('.')?.[0])
  const env = domain.split('.')?.[0]
  return env !== 'localhost' ? env : 'dev'
}

export const isProduction = () => {
  return AppConfig.API_URL.includes('https://api.portal.berlinpackaging.com')
}

export const isValid = (value?: string | number | null | unknown) => {
  if (!value) {
    return false
  }
  if (typeof value === 'string') {
    return value !== ' ' && value.trim() !== '0' && value !== ''
  }
  if (typeof value === 'number') {
    return value !== 0
  }
}

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const productNameRegex = /(<([^>]+)>)/gi

export const getStateOrProvince = (address: CustomerAddress | CheckoutAddress): string => {
  if (address.stateOrProvince && address.stateOrProvince.trim() != '') {
    return address.stateOrProvince
  }
  if (address.stateOrProvinceCode && address.stateOrProvinceCode.trim() != '') {
    return address.stateOrProvinceCode
  }
  return ''
}

export const formatAddress = (address: CustomerAddress | CheckoutAddress): string => {
  const addressArr: string[] = [
    address.address1,
    address.address2,
    address.city,
    getStateOrProvince(address),
    address.postalCode,
    address.countryCode,
  ]
  return addressArr.filter((segment) => segment != null && segment.trim() != '').join(', ')
}

export const maxAddToCartQuantity = 999999

export const getImageUrl = (imgUrl: string, width: number, format: boolean) => {
  const cdnUrl = 'cdn.berlinpackaging.com/'
  const cdnUrl1 = 'cdn.berlinpackaging.com/image/upload/'
  const cdnUrl2 = 'res.cloudinary.com/bp-dev/image/upload/'
  const cdnUrl3 = 'res.cloudinary.com/bp-tst/image/upload/'
  if (imgUrl && imgUrl.includes(cdnUrl1)) {
    imgUrl.split(cdnUrl1).map((img: string) => {
      if (img) imgUrl = `https://${cdnUrl1}${format ? 'f_webp,' : ''}w_${width}/${img}`
    })
  } else if (imgUrl && imgUrl.includes(cdnUrl)) {
    imgUrl.split(cdnUrl).map((img: string) => {
      if (img) imgUrl = `https://${cdnUrl}${format ? 'f_webp,' : ''}w_${width}/${img}`
    })
  } else if (imgUrl && imgUrl.includes(cdnUrl2)) {
    imgUrl.split(cdnUrl2).map((img: string) => {
      if (img) imgUrl = `https://${cdnUrl2}${format ? 'f_webp,' : ''}w_${width}/${img}`
    })
  } else if (imgUrl && imgUrl.includes(cdnUrl3)) {
    imgUrl.split(cdnUrl3).map((img: string) => {
      if (img) imgUrl = `https://${cdnUrl3}${format ? 'f_webp,' : ''}w_${width}/${img}`
    })
  }
  return imgUrl
}

export const getImageScaleUrl = async (imgUrl: string, width: number, format: boolean) => {
  const cdnUrl = 'cdn.berlinpackaging.com/'
  const cdnUrl1 = 'cdn.berlinpackaging.com/image/upload/'
  const cdnUrl2 = 'res.cloudinary.com/bp-dev/image/upload/'
  const cdnUrl3 = 'res.cloudinary.com/bp-tst/image/upload/'

  return new Promise<string>((resolve) => {
    if (imgUrl && imgUrl.includes(cdnUrl1)) {
      imgUrl.split(cdnUrl1).map((img: string) => {
        if (img) imgUrl = `https://${cdnUrl1}e_upscale/${format ? 'f_webp,' : ''}w_${width}/${img}`
      })
    } else if (imgUrl && imgUrl.includes(cdnUrl)) {
      imgUrl.split(cdnUrl).map((img: string) => {
        if (img) imgUrl = `https://${cdnUrl}e_upscale/${format ? 'f_webp,' : ''}w_${width}/${img}`
      })
    } else if (imgUrl && imgUrl.includes(cdnUrl2)) {
      imgUrl.split(cdnUrl2).map((img: string) => {
        if (img) imgUrl = `https://${cdnUrl2}e_upscale/${format ? 'f_webp,' : ''}w_${width}/${img}`
      })
    } else if (imgUrl && imgUrl.includes(cdnUrl3)) {
      imgUrl.split(cdnUrl3).map((img: string) => {
        if (img) imgUrl = `https://${cdnUrl3}e_upscale/${format ? 'f_webp,' : ''}w_${width}/${img}`
      })
    }
    const img = new Image()
    img.src = imgUrl
    img.onload = async () => {
      resolve(imgUrl)
    }
    img.onerror = async () => {
      resolve(imgUrl.replace('e_upscale/', ''))
    }
  })
}

export const fixText = (text: string) => {
  return text
    .replace(/â€œ/g, '"') // Replace “ with "
    .replace(/â€/g, '"') // Replace ” with "
    .replace(/Ãˆ/g, 'È') // Replace Ãˆ with È (as in CRÈME)
    .replace(/â€“/g, '–') // Replace – with -
    .replace(/\\/g, '') // Remove backslashes (\)
}

export const isCrossBrandUser = () => {
  const currentDomain = window.location.hostname
  if (currentDomain === 'localhost') return false // if it's local development return false
  const token = parseJwt(window.localStorage.getItem('token') as string)
  if (!token) return false
  if (token.domains?.includes(currentDomain)) return false
  return true
}
