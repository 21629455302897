import { AxiosRequestConfig } from 'axios'

import { isQorpak } from '../../utils/common'
import { HttpClient } from '../../services/HttpClient'
import * as AppConfig from '../../config'
import { UserRole } from '../../types/user'
import {
  CheckoutInfo,
  ConsignmentLineItem,
  CustomerAddress,
  ExperianAddress,
  NewCustomerAddress,
  TaxExempt,
  toAddressValidationSuggestions,
  toFormattedAddressValidation,
} from '../../types/checkout'
import { Countries } from '../../constants/countries'
import { CanadaStates, UnitedStatesStates } from '../../constants/states'

export class B2BCheckoutRepository extends HttpClient {
  private userType?: UserRole | null

  constructor(userType?: UserRole | null) {
    super(AppConfig.API_URL)
    this.initializeRequestInterceptor()
    this.userType = userType
  }

  private getStateOrProvince = (stateOrProvince: string, country: string): string | undefined => {
    if (country === 'US') {
      return UnitedStatesStates.find((caState) => caState.displayName === stateOrProvince)?.code
    } else if (country === 'CA') {
      return CanadaStates.find((caState) => caState.displayName === stateOrProvince)?.code
    }
    return stateOrProvince ?? undefined
  }

  public submitTaxExempt = async (taxForm: TaxExempt) => {
    // not implemented
    const form = new FormData()
    form.append('firstName', taxForm.firstName)
    form.append('lastName', taxForm.lastName)
    form.append('company', taxForm.companyName)
    form.append('states', taxForm.state)
    form.append('email', taxForm.email)
    form.append('phone', taxForm.phoneNumber)
    taxForm.taxCertificates.forEach((taxCertificate) => {
      form.append('taxExemptionCertificateFile', taxCertificate, taxCertificate.name)
    })
    return this.instance.post('omni-customer/tax-exemptions', form, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  public addCustomerAddress = async (shippingAddress: NewCustomerAddress) => {
    const { data } = await this.instance.post('omni-customer/addresses', shippingAddress)
    return data
  }

  public getCustomerAddresses = async () => {
    const { data } = await this.instance.get<{ addresses: CustomerAddress[] }>('omni-customer/addresses', {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    return data
  }

  public addressValidationSearchCustomerAddress = async (address: ExperianAddress) => {
    const countryInfo = Countries.find((country) => country.iso2 === address.countryCode)
    const { data } = await this.instance.post<any>('omni-address-validation/search/v1', {
      country_iso: countryInfo?.iso3,
      datasets: [countryInfo?.experianAutocomplete],
      max_suggestions: 20,
      components: {
        unspecified: [
          `${address.address1.replaceAll(',', '')} ${address.address2 ? address.address2.replaceAll(',', '') : ''}`.trim(),
          address.city,
          address.stateOrProvince && address.countryCode
            ? this.getStateOrProvince(address.stateOrProvince, address.countryCode)
            : address.stateOrProvince,
          address.postalCode,
        ].filter((addressArr) => !!addressArr),
      },
      options: [
        {
          name: 'search_type',
          value: 'singleline',
        },
        {
          name: 'prompt_set',
          value: 'optimal',
        },
      ],
    })
    return toAddressValidationSuggestions(data.result)
  }

  public addressValidationGetFormattedAddress = async (globalAddressKey: string) => {
    const { data } = await this.instance.get(`omni-address-validation//format/v1/${globalAddressKey}`)
    return toFormattedAddressValidation(data.result)
  }

  public addressValidationValidate = async (address: NewCustomerAddress) => {
    const countryInfo = Countries.find((country) => country.iso2 === address.countryCode)
    const { data } = await this.instance.post<any>('omni-address-validation/validate/v1', {
      country_iso: countryInfo?.iso3,
      datasets: [countryInfo?.experianValidate],
      components: {
        unspecified: [
          `${address.address1}|${address.address2}||${address.city}|${this.getStateOrProvince(
            address.stateOrProvince,
            address.countryCode
          )}|${address.postalCode}`,
        ],
      },
      options: [
        {
          name: 'prompt_set',
          value: 'default',
        },
        {
          name: 'flatten',
          value: 'true',
        },
        {
          name: 'intensity',
          value: 'exact',
        },
      ],
      layouts: ['UniversalAddress'],
      layout_format: 'default',
    })
    return toAddressValidationSuggestions(data.result)
  }

  public createConsignment = async (checkoutId: string, lineItems: ConsignmentLineItem[], shippingAddress: NewCustomerAddress) => {
    const { data } = await this.instance.post<CheckoutInfo>(`omni-order/checkouts/${checkoutId}/consignments`, {
      lineItems,
      address: shippingAddress,
    })
    return data
  }

  public updateConsignment = async (
    checkoutId: string,
    consignmentId: string,
    lineItems: ConsignmentLineItem[],
    shippingAddress: NewCustomerAddress
  ) => {
    const { data } = await this.instance.put(
      `omni-order/checkouts/${checkoutId}/consignments/${consignmentId}`,
      {
        lineItems,
        address: shippingAddress,
      },
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
    )
    return data
  }

  public deleteConsignment = async (checkoutId: string, consignmentId: string) => {
    const { data } = await this.instance.delete(`omni-order/checkouts/${checkoutId}/consignments/${consignmentId}`)
    return data
  }

  public addBillingAddress = async (checkoutId: string, billingAddress: NewCustomerAddress) => {
    const { data } = await this.instance.post(`omni-order/checkouts/${checkoutId}/billing-address`, billingAddress)
    return data
  }

  public getCheckout = async (checkoutId: string) => {
    const { data } = await this.instance.get<CheckoutInfo>(`omni-order/checkouts/${checkoutId}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    return data
  }

  public addShippingOption = async (checkoutId: string, consignmentId: string, shippingOptionId: string) => {
    console.log('addShippingOption', shippingOptionId)

    const { data } = await this.instance.put(
      `omni-order/checkouts/${checkoutId}/consignments/${consignmentId}/shipping`,
      { shippingOptionId },
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
    )
    console.log('addShippingOption', data)
    return data
  }

  public createOrder = async (
    checkoutId: string,
    orderNotes: string,
    purchaseOrder?: { purchaseOrderNumber: string; purchaseOrderDocument?: File },
    collectShippingMethod?: string
  ) => {
    const form = new FormData()
    if (purchaseOrder) {
      form.append('purchaseOrderNumber', purchaseOrder.purchaseOrderNumber)
      if (purchaseOrder.purchaseOrderDocument) {
        form.append('purchaseOrderDocument', purchaseOrder.purchaseOrderDocument)
      }
    }
    if (orderNotes) {
      form.append('notes', orderNotes)
    }
    if (collectShippingMethod) {
      form.append('collectShippingMethod', collectShippingMethod)
    }
    const { data } = await this.instance.post(`omni-order/checkouts/${checkoutId}/orders`, form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  private static classInstance?: B2BCheckoutRepository

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private handleRequest = (config: AxiosRequestConfig) => {
    config.headers!['Ocp-Apim-Subscription-Key'] = AppConfig.APIM_SUBSCRIPTION_KEY

    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }

    // for local development only - values can be
    // https://dev.shop.qorpak.com/ or https://dev.portal.berlinpackaging.com/ depending on the API usage
    if (window.location.hostname === 'localhost') {
      config.headers!['requesting-domain'] = isQorpak() ? 'https://dev.portal.qorpak.com/' : 'https://dev.portal.berlinpackaging.com/'
    }

    return config
  }

  public static getInstance(userType?: UserRole | null) {
    if (!this.classInstance) {
      this.classInstance = new B2BCheckoutRepository(userType)
    }
    return this.classInstance
  }
}
