export const PaymentIcon = () => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_856_9197)">
      <path
        d="M40.6666 8H8.66663C6.44663 8 4.68663 9.78 4.68663 12L4.66663 36C4.66663 38.22 6.44663 40 8.66663 40H40.6666C42.8866 40 44.6666 38.22 44.6666 36V12C44.6666 9.78 42.8866 8 40.6666 8ZM40.6666 36H8.66663V24H40.6666V36ZM40.6666 16H8.66663V12H40.6666V16Z"
        fill="#DBEFFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_856_9197">
        <rect width="48" height="48" fill="white" transform="translate(0.666626)" />
      </clipPath>
    </defs>
  </svg>
)
