import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISiteState {
  isSiteMaintenance: boolean
}

const initialState: ISiteState = {
  isSiteMaintenance: false,
}

export const siteSlice = createSlice({
  name: 'siteSliceStore',
  initialState,
  reducers: {
    setIsSiteMaintenance: (state, { payload }: PayloadAction<boolean>) => {
      state.isSiteMaintenance = payload
    },
  },
})

export const { setIsSiteMaintenance } = siteSlice.actions
export const { reducer } = siteSlice
