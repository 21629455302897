const CottageIcon = ({ color = '#1C1B1F' }: { color: string }) => (
  <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.1965 30.419L5.15153 16.3566L2.45814 18.4277L0.650473 16.0335L5.13942 12.5691L5.12455 7.91916L8.12453 7.90957L8.13209 10.2721L17.1101 3.38081L33.6503 15.928L31.8579 18.2962L29.1514 16.2799L29.1964 30.3423L5.1965 30.419ZM8.18689 27.4095L15.6869 27.3855L15.6677 21.3855L18.6676 21.3759L18.6868 27.3759L26.1868 27.3519L26.1441 14.002L17.1222 7.16829L8.1442 14.0595L8.18689 27.4095ZM5.11975 6.41917C5.11575 5.16918 5.54985 4.10529 6.42205 3.22749C7.29425 2.3497 8.35534 1.9088 9.60534 1.90481C10.0303 1.90345 10.3864 1.75831 10.6734 1.46939C10.9595 1.18147 11.1019 0.825014 11.1005 0.400016L14.1005 0.390422C14.1045 1.64042 13.6704 2.70431 12.7982 3.5821C11.926 4.4599 10.8649 4.90079 9.61493 4.90479C9.18993 4.90615 8.83389 5.05079 8.54681 5.33871C8.26074 5.62762 8.11838 5.98458 8.11973 6.40958L5.11975 6.41917ZM8.18689 27.4095L15.6869 27.3855L18.6868 27.3759L26.1868 27.3519L8.18689 27.4095Z"
      fill={color}
    />
  </svg>
)

export { CottageIcon }
