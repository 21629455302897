export const ExportIcon = ({ color = '#231F20' }: { color?: string }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5_1715)">
      <path
        d="M3.16666 11.3333V12.6667C3.16666 13.0203 3.30713 13.3594 3.55718 13.6095C3.80723 13.8595 4.14637 14 4.49999 14H12.5C12.8536 14 13.1927 13.8595 13.4428 13.6095C13.6928 13.3594 13.8333 13.0203 13.8333 12.6667V11.3333"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16666 7.33334L8.49999 10.6667L11.8333 7.33334"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.5 2.66666V10.6667" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5_1715">
        <rect width="16" height="16" fill={color} transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
