// material-ui
import { useTheme } from '@mui/material/styles'
import { IconButton } from '@mui/material'

import { useAppSelector, useAppDispatch } from '../../../../hooks/reduxHooks'
import { b2bCartSlice } from '../../../../stores/b2b/CartStore/cartSlice'
import { b2cCartSlice } from '../../../../stores/b2c/CartStore/cartSlice'
import { selectCartItems as b2bSelectCartItems } from '../../../../stores/b2b/CartStore/selectors'
import { selectCartItems as b2cSelectCartItems } from '../../../../stores/b2c/CartStore/selectors'
import { trackActionClicked } from '../../../../utils/segment'
import { selectUser } from '../../../../stores/AuthStore/selectors'
import { ShoppingCartIcon } from '../../../../components/icons'

const CartSection = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const isB2BCheckoutEnabled = user?.userRoles?.includes('Customer B2B Checkout')
  const b2bCartItems = useAppSelector(b2bSelectCartItems)
  const b2cCartItems = useAppSelector(b2cSelectCartItems)

  return (
    <IconButton
      onClick={() => {
        if (isB2BCheckoutEnabled) {
          trackActionClicked('View Cart')
          dispatch(b2bCartSlice.actions.openCartDrawer())
        } else {
          dispatch(b2cCartSlice.actions.openCartDrawer())
        }
      }}
      sx={{
        px: '4px',
        fontSize: '14px',
        fontWeight: 500,
        display: 'flex',
        gap: '4px',
        color: theme.palette.common.black,
        '&.MuiIconButton-root:hover, &.MuiIconButton-root:focus': {
          background: 'none',
          color: theme.palette.common.black,
        },
      }}
    >
      <ShoppingCartIcon />
      {isB2BCheckoutEnabled ? b2bCartItems?.length ?? 0 : b2cCartItems?.length ?? 0}
    </IconButton>
  )
}

export default CartSection
