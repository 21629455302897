const TruckIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_856_5985)">
      <path
        d="M26.6666 10.6668H22.6666V5.3335H3.99992C2.53325 5.3335 1.33325 6.5335 1.33325 8.00016V22.6668H3.99992C3.99992 24.8802 5.78658 26.6668 7.99992 26.6668C10.2133 26.6668 11.9999 24.8802 11.9999 22.6668H19.9999C19.9999 24.8802 21.7866 26.6668 23.9999 26.6668C26.2133 26.6668 27.9999 24.8802 27.9999 22.6668H30.6666V16.0002L26.6666 10.6668ZM7.99992 24.6668C6.89325 24.6668 5.99992 23.7735 5.99992 22.6668C5.99992 21.5602 6.89325 20.6668 7.99992 20.6668C9.10659 20.6668 9.99992 21.5602 9.99992 22.6668C9.99992 23.7735 9.10659 24.6668 7.99992 24.6668ZM25.9999 12.6668L28.6132 16.0002H22.6666V12.6668H25.9999ZM23.9999 24.6668C22.8933 24.6668 21.9999 23.7735 21.9999 22.6668C21.9999 21.5602 22.8933 20.6668 23.9999 20.6668C25.1066 20.6668 25.9999 21.5602 25.9999 22.6668C25.9999 23.7735 25.1066 24.6668 23.9999 24.6668Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_856_5985">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export { TruckIcon }
