import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'

import { OmniChannelFeature } from '../../types/common'

export interface FeatureFlagState {
  featureFlags: Record<OmniChannelFeature, boolean>
}

const initialState: FeatureFlagState = {
  featureFlags: {} as Record<OmniChannelFeature, boolean>,
}

export const featureFlagSlice = createSlice({
  name: 'featureFlagStore',
  initialState,
  reducers: {
    enableFeature: (state, { payload }: PayloadAction<OmniChannelFeature>) => {
      state.featureFlags[payload] = true
    },
    disableFeature: (state, { payload }: PayloadAction<OmniChannelFeature>) => {
      state.featureFlags = omit(state.featureFlags, [payload]) as Record<OmniChannelFeature, boolean>
    },
    toggleFeature: (state, { payload }: PayloadAction<OmniChannelFeature>) => {
      if (state.featureFlags[payload]) {
        state.featureFlags = omit(state.featureFlags, [payload]) as Record<OmniChannelFeature, boolean>
      } else {
        state.featureFlags[payload] = true
      }
    },
  },
})

export const { enableFeature, disableFeature, toggleFeature } = featureFlagSlice.actions
export const { reducer } = featureFlagSlice
