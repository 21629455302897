import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  CustomerAddress,
  FormattedAddressValidation,
  AddressSuggestion,
  DeliveryMethod,
  OrderConfirmationDetails,
} from '../../../types/checkout'

export interface B2BCheckoutState {
  consignmentId: string | undefined
  savedShippingAddress: CustomerAddress[]
  savedBillingAddress: CustomerAddress[]
  consignmentDetails: any
  shipping: {
    address: CustomerAddress | undefined
    shippingInstructions: string
  }
  billing: {
    address: CustomerAddress | undefined
  }
  tax: number
  discount: {
    code: string
    amount: number
  }
  purchaseOrder: {
    purchaseOrderNumber: string
    purchaseOrderDocument: File | undefined
  }
  deliveryMethod: DeliveryMethod | undefined
  addNewAddress: {
    addressSuggestions: AddressSuggestion[]
    selectedAddressSuggestionDetails: FormattedAddressValidation | undefined
    validationError: { confidence: string; suggestions: { globalAddressKey: string; text: string }[] } | undefined
    addAddressError: string | undefined
  }
  orderConfirmation:
    | {
        details: OrderConfirmationDetails
        purchaseOrderNumber: string | undefined
      }
    | undefined

  // ui states
  addNewAddressModalIsOpen: {
    isOpen: boolean
    variant?: 'billing' | 'shipping'
  }
  editAddressModalIsOpen: boolean
  selectShippingMethodIsOpen: boolean
  addDeliveryNotesModalIsOpen: boolean
  termsAndConditionsModalIsOpen: boolean
  purchaseOrderModalIsOpen: boolean
  purchaseOrderNumberModalIsOpen: boolean
  taxExemptSubmissionResp: {
    status?: string
  }
  orderNotes?: string
  orderError?: string
}

const initialState: B2BCheckoutState = {
  consignmentId: undefined,
  savedShippingAddress: [],
  savedBillingAddress: [],
  consignmentDetails: undefined,
  shipping: {
    address: undefined,
    shippingInstructions: '',
  },
  billing: {
    address: undefined,
  },
  tax: 0,
  discount: {
    code: '',
    amount: 0,
  },
  purchaseOrder: {
    purchaseOrderNumber: '',
    purchaseOrderDocument: undefined,
  },
  deliveryMethod: undefined,
  addNewAddress: {
    addressSuggestions: [],
    selectedAddressSuggestionDetails: undefined,
    validationError: undefined,
    addAddressError: undefined,
  },
  orderConfirmation: undefined,

  // ui states
  addNewAddressModalIsOpen: {
    isOpen: false,
  },
  editAddressModalIsOpen: false,
  selectShippingMethodIsOpen: false,
  addDeliveryNotesModalIsOpen: false,
  termsAndConditionsModalIsOpen: false,
  purchaseOrderModalIsOpen: false,
  purchaseOrderNumberModalIsOpen: false,
  taxExemptSubmissionResp: {},
  orderNotes: undefined,
  orderError: undefined,
}

export const b2bCheckoutSlice = createSlice({
  name: 'b2bCheckoutStore',
  initialState,
  reducers: {
    setOrderNotes: (state, { payload }: PayloadAction<{ orderNotes: string }>) => {
      state.orderNotes = payload.orderNotes
    },
    // consignment operations
    setConsignmentId: (state, { payload }: PayloadAction<{ consignmentId: string }>) => {
      state.consignmentId = payload.consignmentId
    },
    clearConsignmentId: (state) => {
      state.consignmentId = undefined
    },
    setConsignmentDetails: (state, { payload }: PayloadAction<{ consignment: any }>) => {
      state.consignmentDetails = payload.consignment
    },
    clearConsignmentDetails: (state) => {
      state.consignmentDetails = undefined
    },

    setSavedShippingAddresses: (state, { payload }: PayloadAction<{ addresses: CustomerAddress[] }>) => {
      state.savedShippingAddress = payload.addresses
    },
    setSavedBillingAddresses: (state, { payload }: PayloadAction<{ addresses: CustomerAddress[] }>) => {
      state.savedBillingAddress = payload.addresses
    },
    setSavedAddresses: (
      state,
      { payload }: PayloadAction<{ shippingAddresses: CustomerAddress[]; billingAddresses: CustomerAddress[] }>
    ) => {
      state.savedShippingAddress = payload.shippingAddresses
      state.savedBillingAddress = payload.billingAddresses
    },

    setOrderError: (state, { payload }: PayloadAction<string | undefined>) => {
      state.orderError = payload
    },

    // shipping operations
    setShippingAddress: (state, { payload }: PayloadAction<{ address: CustomerAddress }>) => {
      state.shipping.address = payload.address
    },
    clearShippingAddress: (state) => {
      state.shipping.address = undefined
    },
    setShippingInstructions: (state, { payload }: PayloadAction<{ shippingInstructions: string }>) => {
      state.shipping.shippingInstructions = payload.shippingInstructions
    },

    // billing operations
    setBillingAddress: (state, { payload }: PayloadAction<{ address: CustomerAddress }>) => {
      state.billing.address = payload.address
    },
    clearBillingAddress: (state) => {
      state.billing.address = undefined
    },

    // discount operations
    addDiscount: (state, { payload }: PayloadAction<{ code: string; amount: number }>) => {
      state.discount = payload
    },

    // purchase order number operations
    addPurchaseOrderNumber: (state, { payload }: PayloadAction<string>) => {
      state.purchaseOrder.purchaseOrderNumber = payload
    },
    clearPurchaseOrderNumber: (state) => {
      state.purchaseOrder.purchaseOrderNumber = ''
    },

    // purchase order attachement operations
    addPurchaseOrderAttachments: (state, { payload }: PayloadAction<File>) => {
      state.purchaseOrder.purchaseOrderDocument = payload
    },
    clearPurchaseOrderAttachments: (state) => {
      state.purchaseOrder.purchaseOrderDocument = undefined
    },

    // delivery method operations
    setDeliveryMethod: (state, { payload }: PayloadAction<DeliveryMethod>) => {
      state.deliveryMethod = payload
    },
    clearDeliveryMethod: (state) => {
      state.deliveryMethod = undefined
    },

    setAddressSuggestions: (state, { payload }: PayloadAction<any[]>) => {
      if (!state.addNewAddress) {
        state.addNewAddress = {
          addressSuggestions: payload,
          selectedAddressSuggestionDetails: undefined,
          validationError: undefined,
          addAddressError: undefined,
        }
      } else {
        state.addNewAddress.addressSuggestions = payload
      }
    },
    setAddNewAddressValidationError: (state, { payload }: PayloadAction<any>) => {
      state.addNewAddress.validationError = payload
    },
    setSelectedAddressSuggestionDetails: (state, { payload }: PayloadAction<FormattedAddressValidation>) => {
      state.addNewAddress.selectedAddressSuggestionDetails = payload
    },
    clearAddressSuggestions: (state) => {
      state.addNewAddress = {
        addressSuggestions: [],
        selectedAddressSuggestionDetails: undefined,
        validationError: undefined,
        addAddressError: undefined,
      }
    },
    setAddAddressError: (state, { payload }: PayloadAction<string>) => {
      state.addNewAddress = {
        ...state.addNewAddress,
        addAddressError: payload,
      }
    },
    clearAddAddressError: (state) => {
      state.addNewAddress = {
        ...state.addNewAddress,
        addAddressError: undefined,
      }
    },

    // order confirmation operations
    setOrderConfirmationDetails: (
      state,
      { payload }: PayloadAction<{ orderInfo: OrderConfirmationDetails; purchaseOrderNumber?: string }>
    ) => {
      state.orderConfirmation = {
        details: payload.orderInfo,
        purchaseOrderNumber: payload.purchaseOrderNumber,
      }
    },
    clearOrderConfirmationDetails: (state) => {
      state.orderConfirmation = undefined
    },
    resetCheckout: (state) => {
      state.savedShippingAddress = []
      state.savedBillingAddress = []
      state.shipping = {
        address: undefined,
        shippingInstructions: '',
      }
      state.billing = {
        address: undefined,
      }
      state.purchaseOrder = {
        purchaseOrderNumber: '',
        purchaseOrderDocument: undefined,
      }
      state.discount = {
        code: '',
        amount: 0,
      }
      state.tax = 0
      state.deliveryMethod = undefined
      state.consignmentDetails = undefined
    },

    // ui operations
    openAddNewAddressModal: (state, { payload }: PayloadAction<'billing' | 'shipping'>) => {
      state.addNewAddressModalIsOpen = {
        isOpen: true,
        variant: payload,
      }
    },
    closeAddNewAddressModal: (state) => {
      state.addNewAddressModalIsOpen = {
        isOpen: false,
      }
    },
    openEditAddressModal: (state) => {
      state.editAddressModalIsOpen = true
    },
    closeEditAddressModal: (state) => {
      state.editAddressModalIsOpen = false
    },
    openSelectShippingMethodModal: (state) => {
      state.selectShippingMethodIsOpen = true
    },
    closeSelectShippingMethodModal: (state) => {
      state.selectShippingMethodIsOpen = false
    },
    openAddDeliveryNotesModal: (state) => {
      state.addDeliveryNotesModalIsOpen = true
    },
    closeAddDeliveryNotesModal: (state) => {
      state.addDeliveryNotesModalIsOpen = false
    },
    openTermsAndConditionsModal: (state) => {
      state.termsAndConditionsModalIsOpen = true
    },
    closeTermsAndConditionsModal: (state) => {
      state.termsAndConditionsModalIsOpen = false
    },
    openPurchaseOrderModal: (state) => {
      state.purchaseOrderModalIsOpen = true
    },
    closePurchaseOrderModal: (state) => {
      state.purchaseOrderModalIsOpen = false
    },
    openPurchaseOrderNumberModal: (state) => {
      state.purchaseOrderNumberModalIsOpen = true
    },
    closePurchaseOrderNumberModal: (state) => {
      state.purchaseOrderNumberModalIsOpen = false
    },

    updateTaxExemptSubmissionResp: (state, { payload }: PayloadAction<string>) => {
      state.taxExemptSubmissionResp.status = payload
    },
    resetTaxExemptSubmissionResp: (state) => {
      delete state.taxExemptSubmissionResp.status
    },
  },
})

export const {
  // consignment
  setConsignmentId,
  clearConsignmentId,

  // address
  setSavedShippingAddresses,
  setSavedBillingAddresses,
  setSavedAddresses,

  setOrderError,

  // shipping
  setShippingAddress,
  clearShippingAddress,
  setShippingInstructions,

  //consignment
  setConsignmentDetails,
  clearConsignmentDetails,

  // billing
  setBillingAddress,
  clearBillingAddress,

  // discount
  addDiscount,

  // purchase order number
  addPurchaseOrderNumber,
  clearPurchaseOrderNumber,
  addPurchaseOrderAttachments,
  clearPurchaseOrderAttachments,
  setAddNewAddressValidationError,

  // delivery method
  setDeliveryMethod,
  clearDeliveryMethod,

  // add new address
  setAddressSuggestions,
  setSelectedAddressSuggestionDetails,
  clearAddressSuggestions,
  setAddAddressError,
  clearAddAddressError,

  // order confirmation
  setOrderConfirmationDetails,
  clearOrderConfirmationDetails,
  resetCheckout,

  // ui
  openAddNewAddressModal,
  closeAddNewAddressModal,
  openEditAddressModal,
  closeEditAddressModal,
  openSelectShippingMethodModal,
  closeSelectShippingMethodModal,
  openAddDeliveryNotesModal,
  closeAddDeliveryNotesModal,
  openTermsAndConditionsModal,
  closeTermsAndConditionsModal,
  openPurchaseOrderModal,
  closePurchaseOrderModal,
  openPurchaseOrderNumberModal,
  closePurchaseOrderNumberModal,
  // API
  updateTaxExemptSubmissionResp,
  resetTaxExemptSubmissionResp,

  setOrderNotes,
} = b2bCheckoutSlice.actions
export const { reducer } = b2bCheckoutSlice
