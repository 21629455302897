const PhotoIcon = () => (
  <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.7554 7.45925V5.18898H19.3966V2.91871H21.7554V0.648438H24.1141V2.91871H26.4728V5.18898H24.1141V7.45925H21.7554ZM2.88558 23.3511C2.23693 23.3511 1.68184 23.129 1.22032 22.6848C0.75801 22.2398 0.526855 21.7052 0.526855 21.0809V7.45925C0.526855 6.83492 0.75801 6.30065 1.22032 5.85644C1.68184 5.41146 2.23693 5.18898 2.88558 5.18898H6.60057L8.78238 2.91871H15.8586V5.18898H9.81433L7.66199 7.45925H2.88558V21.0809H21.7554V10.8647H24.1141V21.0809C24.1141 21.7052 23.8833 22.2398 23.4218 22.6848C22.9595 23.129 22.404 23.3511 21.7554 23.3511H2.88558ZM12.3205 19.3782C13.7947 19.3782 15.0479 18.8817 16.0803 17.8889C17.1118 16.8952 17.6276 15.689 17.6276 14.2701C17.6276 12.8511 17.1118 11.6449 16.0803 10.6512C15.0479 9.65838 13.7947 9.16195 12.3205 9.16195C10.8463 9.16195 9.593 9.65838 8.56066 10.6512C7.52912 11.6449 7.01334 12.8511 7.01334 14.2701C7.01334 15.689 7.52912 16.8952 8.56066 17.8889C9.593 18.8817 10.8463 19.3782 12.3205 19.3782ZM12.3205 17.1079C11.4949 17.1079 10.7971 16.8336 10.2271 16.2849C9.65708 15.7363 9.37206 15.0647 9.37206 14.2701C9.37206 13.4755 9.65708 12.8038 10.2271 12.2552C10.7971 11.7065 11.4949 11.4322 12.3205 11.4322C13.146 11.4322 13.8438 11.7065 14.4138 12.2552C14.9839 12.8038 15.2689 13.4755 15.2689 14.2701C15.2689 15.0647 14.9839 15.7363 14.4138 16.2849C13.8438 16.8336 13.146 17.1079 12.3205 17.1079Z"
      fill="white"
    />
  </svg>
)

export { PhotoIcon }
