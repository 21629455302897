import { useMemo } from 'react'
import { useDashboardMenuItems } from './dashboard'
import userManagement from './user-managemant'
// import newItemSetup from './new-item'
import closeSideBar from './close'
import signOut from './signout'
// import { useResourcesMenuItems } from './resources'
// import { useProductsMenuItems } from './products'
// import { useSystemMenuItems } from './system'

// ==============================|| MENU ITEMS ||============================== //

const useMenuItems = () => {
  const { dashboard } = useDashboardMenuItems()
  // const { products } = useProductsMenuItems()
  // const { resources } = useResourcesMenuItems()
  // const { system } = useSystemMenuItems()

  const items = useMemo(() => [dashboard, userManagement], [])
  const actionItems = useMemo(() => [signOut, closeSideBar], [])

  return { items, actionItems }
}

export { useMenuItems }
