import MuiDrawer from '@mui/material/Drawer'

type Props = {
  children: React.ReactNode
  open: boolean
  onToggle: () => void
}

function Drawer({ children, open, onToggle }: Props) {
  return (
    <MuiDrawer anchor="right" open={open} onClose={onToggle}>
      {children}
    </MuiDrawer>
  )
}

export { Drawer }
