import { useEffect, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { SearchOutlined } from '@mui/icons-material'

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [searchString, setSearchString] = useState<string>('')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setSearchString(searchParams.get('q') ?? '')
  }, [searchParams])

  const search = () => {
    if (searchString) {
      navigate({
        pathname: '/products/list',
        search: createSearchParams({
          q: searchString,
        }).toString(),
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <OutlinedInput
        placeholder="Search"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            search()
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="Search" onClick={() => search()} onMouseDown={() => search()} edge="end">
              <SearchOutlined sx={{ color: theme.palette.common.black }} />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          width: '100%',
          maxWidth: 600,
          px: '14px',
          background: '#F9FBFB !important',
          '& fieldset': {
            borderColor: '#C4C4C4',
          },
          '.MuiOutlinedInput-input': {
            px: '0 !important',
            py: '14px !important',
            background: '#F9FBFB',
            fontSize: '14px',
            lineHeight: '100%',
          },
        }}
        aria-describedby="search-helper-text"
      />
    </Box>
  )
}

export default SearchSection
