import { AppThunk } from '../rootStore'
import { setPixels } from './bloomreachSlice'
import { addRequest, removeRequest } from '../LoadingStore/loadingSlice'
import { Requests } from '../../types/common'
import { BloomReachRepository } from '../../repositories/BloomReachRepository'

export const getPixels = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getPixels))

    const bloomReachRepository = BloomReachRepository.getInstance()

    const pixels = await bloomReachRepository.getBloomReachPixels()

    dispatch(setPixels(pixels))
  } catch (err) {
    console.log('[getPixels.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getPixels))
  }
}
