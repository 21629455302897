import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthedContent from './AuthedContent'

import { useAuth } from '../contexts/auth.context'
import { useAppDispatch } from '../hooks/reduxHooks'
import { setUserInfo, setRole, setLogoutInProgress } from '../stores/AuthStore/authSlice'
import { parseJwt, isValidJwt } from '../utils/common'
import { trackUserLogin } from '../utils/segment'
import { UserRole } from '../types/user'
import { getCustomerPhoto } from '../stores/UserStore/actions'

type Props = {
  token: string
}

const CustomerMainContent = ({ token }: Props) => {
  const navigate = useNavigate()

  const { setToken } = useAuth()
  const dispatch = useAppDispatch()

  const loadedRef = useRef(false)

  useEffect(() => {
    if (token && !isValidJwt(token)) {
      dispatch(setRole(null))
      dispatch(setLogoutInProgress(true))
      window.localStorage.removeItem('token')
      navigate('/')
    } else {
      const jwtPayload = parseJwt(token)

      setToken(token)
      window.localStorage.setItem('token', token)

      dispatch(setRole(UserRole.CUSTOMER))
      dispatch(
        setUserInfo({
          firstName: jwtPayload.given_name ?? '',
          lastName: jwtPayload.family_name ?? '',
          email: jwtPayload.email ?? jwtPayload.signInName ?? '',
          profileImg: jwtPayload.imageUrl ?? '',
          company: jwtPayload.extension_company ?? '',
          newUser: jwtPayload.newUser,
          userId: jwtPayload.sub ?? '',
          userRoles: jwtPayload.roles ?? [],
        })
      )
      dispatch(getCustomerPhoto())

      // segment tracking
      const email = jwtPayload?.email
      const userId = jwtPayload?.sub
      if (email && userId) {
        trackUserLogin(userId, email)
      }

      if (jwtPayload.newUser) {
        navigate('/onboarding')
      }
    }
    loadedRef.current = true
  }, [token, setToken, dispatch])

  return <div className="App customer-portal">{loadedRef.current ? <AuthedContent /> : null}</div>
}

export default CustomerMainContent
