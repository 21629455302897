import { Link } from 'react-router-dom'

// material-ui
import { ButtonBase } from '@mui/material'

// project imports
import config from '../../../config'
import Logo from '../../../components/Logo'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath} sx={{ borderRadius: 1 }}>
    <Logo />
  </ButtonBase>
)

export default LogoSection
