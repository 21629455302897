const ShoppingCartIcon = ({ color = '#231F20' }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <g clipPath="url(#clip0_1664_2022)">
      <path
        d="M3.3335 16.3333C3.3335 16.7753 3.50909 17.1992 3.82165 17.5118C4.13421 17.8244 4.55814 18 5.00016 18C5.44219 18 5.86611 17.8244 6.17867 17.5118C6.49123 17.1992 6.66683 16.7753 6.66683 16.3333C6.66683 15.8913 6.49123 15.4673 6.17867 15.1548C5.86611 14.8422 5.44219 14.6666 5.00016 14.6666C4.55814 14.6666 4.13421 14.8422 3.82165 15.1548C3.50909 15.4673 3.3335 15.8913 3.3335 16.3333Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.3333C12.5 16.7753 12.6756 17.1992 12.9882 17.5118C13.3007 17.8244 13.7246 18 14.1667 18C14.6087 18 15.0326 17.8244 15.3452 17.5118C15.6577 17.1992 15.8333 16.7753 15.8333 16.3333C15.8333 15.8913 15.6577 15.4673 15.3452 15.1548C15.0326 14.8422 14.6087 14.6666 14.1667 14.6666C13.7246 14.6666 13.3007 14.8422 12.9882 15.1548C12.6756 15.4673 12.5 15.8913 12.5 16.3333Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.1668 14.6667H5.00016V3H3.3335" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5 4.66663L16.6667 5.49996L15.8333 11.3333H5"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1664_2022">
        <rect width="20" height="20" fill="whnoneite" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
export { ShoppingCartIcon }
