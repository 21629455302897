import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Cart } from '../../../types/cart'

export interface B2BCartState {
  cartConfig: Cart | undefined
  productCategoryMap: Record<string, string> | undefined // used for Segment tracking
  // ui states
  cartDrawerIsOpen: boolean
  emailCartModalIsOpen: boolean
  estimateShippingModalIsOpen: boolean
  taxExemptModalIsOpen: boolean
  isLoading: boolean
  skuLoading: string
  cartId: string | undefined
}

const initialState: B2BCartState = {
  cartConfig: undefined,

  productCategoryMap: undefined,

  // ui states
  cartDrawerIsOpen: false,
  emailCartModalIsOpen: false,
  estimateShippingModalIsOpen: false,
  taxExemptModalIsOpen: false,
  isLoading: false,
  skuLoading: '',
  cartId: undefined,
}

export const b2bCartSlice = createSlice({
  name: 'b2bCartStore',
  initialState,
  reducers: {
    // cart operations
    setCartFromApi: (state, { payload }: PayloadAction<Cart>) => {
      state.cartConfig = payload
      state.cartId = payload.id
    },

    clearCart: (state) => {
      state.cartConfig = undefined
      state.cartId = undefined
    },

    addToProductCategoryMap: (state, { payload }: { payload: { sku: string; category: string } }) => {
      if (state.productCategoryMap) {
        state.productCategoryMap[payload.sku] = payload.category
      } else {
        state.productCategoryMap = {
          [payload.sku]: payload.category,
        }
      }
    },
    removeFromProductCategoryMap: (state, { payload }: { payload: { sku: string } }) => {
      delete state.productCategoryMap?.[payload.sku]
    },
    clearProductCategoryMap: (state) => {
      state.productCategoryMap = undefined
    },

    // ui operations
    setIsLoading: (state, { payload }: { payload: { isLoading: boolean; sku: string } }) => {
      state.isLoading = payload.isLoading
      state.skuLoading = payload.sku
    },
    openCartDrawer: (state) => {
      state.cartDrawerIsOpen = true
    },
    closeCartDrawer: (state) => {
      state.cartDrawerIsOpen = false
    },
    openEmailCartModal: (state) => {
      state.emailCartModalIsOpen = true
    },
    closeEmailCartModal: (state) => {
      state.emailCartModalIsOpen = false
    },
    openEstimateShippingModal: (state) => {
      state.estimateShippingModalIsOpen = true
    },
    closeEstimateShippingModal: (state) => {
      state.estimateShippingModalIsOpen = false
    },
    openTaxExemptModal: (state) => {
      state.taxExemptModalIsOpen = true
    },
    closeTaxExemptModal: (state) => {
      state.taxExemptModalIsOpen = false
    },
  },
})

export const {
  // cart API
  setCartFromApi,

  // cart operations
  clearCart,

  // for Segment tracking
  addToProductCategoryMap,
  removeFromProductCategoryMap,
  clearProductCategoryMap,

  // ui
  openCartDrawer,
  setIsLoading,
  closeCartDrawer,
  openEmailCartModal,
  closeEmailCartModal,
  openEstimateShippingModal,
  closeEstimateShippingModal,
  openTaxExemptModal,
  closeTaxExemptModal,
} = b2bCartSlice.actions
export const { reducer } = b2bCartSlice
