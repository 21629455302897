import axios, { AxiosInstance, AxiosResponse } from 'axios'

export abstract class HttpClient {
  protected readonly instance: AxiosInstance

  protected constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    })

    this.initializeResponseInterceptor()
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse, this.handleError)
  }

  private handleResponse = (response: AxiosResponse) => {
    if (response.data.hasOwnProperty('HasError')) {
      return this.handleError(response)
    }
    // Retrieve feature flags from local storage
    let featureFlags: any = {}
    const featureFlagReducer = JSON.parse(window.localStorage.getItem('persist:featureFlagReducer') ?? '{}')
    if (featureFlagReducer.featureFlags) featureFlags = JSON.parse(featureFlagReducer.featureFlags)
    // Check if mock data feature flag is enabled
    if (featureFlags['mock-data'] && response.data.hasOwnProperty('metadata') && response.data.metadata.nonImplementedFields?.length) {
      const { nonImplementedFields } = response.data.metadata
      this.parseMockResponse(response.data, nonImplementedFields)
    }

    return response
  }

  protected handleError = (error: any) => Promise.reject(error)

  protected parseMockResponse = (responseData: any, objects: any) => {
    if (objects.length) {
      objects.forEach((object: any) => {
        const { objectName, subObjects, fields } = object
        if (fields.length) {
          fields.forEach((field: string) => {
            if (objectName === 'root') responseData[field] = `mock - [${responseData[field]}]`
            else responseData[objectName][field] = `mock - [${responseData[objectName][field]}]`
          })
        }
        if (objectName === 'root') {
          this.parseMockResponse(responseData, subObjects)
        } else if (responseData[objectName]) {
          this.parseMockResponse(responseData[objectName], subObjects)
        }
      })
    }
  }
}
