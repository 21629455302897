import { MenuItem, Select, FormControl, InputLabel, useTheme } from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

type Props<T extends FieldValues> = {
  name: Path<T>
  control?: Control<T, any>
  label?: string
  color?: 'primary' | 'secondary'
  defaultValue?: any
  controllerDefaultValue?: any
  variant?: 'outlined' | 'standard' | 'filled'
  style?: any
  error?: boolean
  required?: boolean
  disableUnderline?: boolean
  options: {
    value: string
    label: string
  }[]
  sx?: any
}

function FormDropdownMultiple<T extends FieldValues>({
  name,
  control,
  label,
  color = 'primary',
  options,
  error = false,
  defaultValue,
  variant = 'outlined',
  style,
  disableUnderline = false,
  controllerDefaultValue,
  required = false,
  sx,
}: Props<T>) {
  const theme = useTheme()
  const generateSelectOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    })
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={controllerDefaultValue?.value}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth margin="dense" variant={variant} style={style}>
          {label && <InputLabel id={`select-${name}-label`} color={color}>{`${label}*`}</InputLabel>}
          <Select
            error={error}
            color={color}
            labelId={`select-${name}-label`}
            id={`select-${name}`}
            label={label}
            required={required}
            value={value}
            sx={{
              sx,
              '.MuiSelect-select': {
                color: theme.palette.common.black,
              },
            }}
            disableUnderline={disableUnderline}
            defaultValue={defaultValue}
            onChange={onChange}
            multiple
          >
            {generateSelectOptions()}
          </Select>
        </FormControl>
      )}
    />
  )
}

export { FormDropdownMultiple }
