export const CanadaStates = [
  {
    code: 'AB',
    displayName: 'Alberta',
  },
  {
    code: 'BC',
    displayName: 'British Columbia',
  },
  {
    code: 'MB',
    displayName: 'Manitoba',
  },
  {
    code: 'NB',
    displayName: 'New Brunswick',
  },
  {
    code: 'NF',
    displayName: 'Newfoundland',
  },
  {
    code: 'NT',
    displayName: 'Northwest Territories',
  },
  {
    code: 'NS',
    displayName: 'Nova Scotia',
  },
  {
    code: 'NU',
    displayName: 'Nunavut',
  },
  {
    code: 'ON',
    displayName: 'Ontario',
  },
  {
    code: 'PE',
    displayName: 'Price Edward Island',
  },
  {
    code: 'QC',
    displayName: 'Quebec',
  },
  {
    code: 'SK',
    displayName: 'Saskatchewan',
  },
  {
    code: 'YT',
    displayName: 'Yukon Territory',
  },
]

export const UnitedStatesStates = [
  {
    code: 'AL',
    displayName: 'Alabama',
  },
  {
    code: 'AK',
    displayName: 'Alaska',
  },
  {
    code: 'AS',
    displayName: 'American Samoa',
  },
  {
    code: 'AZ',
    displayName: 'Arizona',
  },
  {
    code: 'AR',
    displayName: 'Arkansas',
  },
  {
    code: 'AA',
    displayName: 'Armed Forces - Americas',
  },
  {
    code: 'AE',
    displayName: 'Armed Forces - Europe',
  },
  {
    code: 'AP',
    displayName: 'Armed Forces - Pacific',
  },
  {
    code: 'CA',
    displayName: 'California',
  },
  {
    code: 'CO',
    displayName: 'Colorado',
  },
  {
    code: 'CT',
    displayName: 'Connecticut',
  },
  {
    code: 'DE',
    displayName: 'Delaware',
  },
  {
    code: 'DC',
    displayName: 'District of Columbia',
  },
  {
    code: 'FL',
    displayName: 'Florida',
  },
  {
    code: 'GA',
    displayName: 'Georgia',
  },
  {
    code: 'GU',
    displayName: 'Guam',
  },
  {
    code: 'HI',
    displayName: 'Hawaii',
  },
  {
    code: 'ID',
    displayName: 'Idaho',
  },
  {
    code: 'IL',
    displayName: 'Illinois',
  },
  {
    code: 'IN',
    displayName: 'Indiana',
  },
  {
    code: 'IA',
    displayName: 'Iowa',
  },
  {
    code: 'KS',
    displayName: 'Kansas',
  },
  {
    code: 'KY',
    displayName: 'Kentucky',
  },
  {
    code: 'LA',
    displayName: 'Louisiana',
  },
  {
    code: 'ME',
    displayName: 'Maine',
  },
  {
    code: 'MD',
    displayName: 'Maryland',
  },
  {
    code: 'MA',
    displayName: 'Massachusetts',
  },
  {
    code: 'MI',
    displayName: 'Michigan',
  },
  {
    code: 'MN',
    displayName: 'Minnesota',
  },
  {
    code: 'MS',
    displayName: 'Mississippi',
  },
  {
    code: 'MO',
    displayName: 'Missouri',
  },
  {
    code: 'MT',
    displayName: 'Montana',
  },
  {
    code: 'NE',
    displayName: 'Nebraska',
  },
  {
    code: 'NV',
    displayName: 'Nevada',
  },
  {
    code: 'NH',
    displayName: 'New Hampshire',
  },
  {
    code: 'NJ',
    displayName: 'New Jersey',
  },
  {
    code: 'NM',
    displayName: 'New Mexico',
  },
  {
    code: 'NY',
    displayName: 'New York',
  },
  {
    code: 'NC',
    displayName: 'North Carolina',
  },
  {
    code: 'ND',
    displayName: 'North Dakota',
  },
  {
    code: 'MP',
    displayName: 'MP; Northern Mariana Islands',
  },
  {
    code: 'OH',
    displayName: 'Ohio',
  },
  {
    code: 'OK',
    displayName: 'Oklahoma',
  },
  {
    code: 'OR',
    displayName: 'Oregon',
  },
  {
    code: 'PA',
    displayName: 'Pennsylvania',
  },
  {
    code: 'PR',
    displayName: 'Puerto Rico',
  },
  {
    code: 'RI',
    displayName: 'Rhode Island',
  },
  {
    code: 'SC',
    displayName: 'South Carolina',
  },
  {
    code: 'SD',
    displayName: 'South Dakota',
  },
  {
    code: 'TN',
    displayName: 'Tennessee',
  },
  {
    code: 'TX',
    displayName: 'Texas',
  },
  {
    code: 'UT',
    displayName: 'Utah',
  },
  {
    code: 'UM',
    displayName: 'U.S. Minor Outlying Islands',
  },
  {
    code: 'VT',
    displayName: 'Vermont',
  },
  {
    code: 'VA',
    displayName: 'Virginia',
  },
  {
    code: 'VI',
    displayName: 'Virgin Islands',
  },
  {
    code: 'WA',
    displayName: 'Washington',
  },
  {
    code: 'WV',
    displayName: 'West Virginia',
  },
  {
    code: 'WI',
    displayName: 'Wisconsin',
  },
  {
    code: 'WY',
    displayName: 'Wyoming',
  },
]

// we'll need to transition other parts of the app that is using the following code
// to use the UnitedStatesStates and CanadaStates instead
export const States = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]
