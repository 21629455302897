import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { HttpClient } from '../../services/HttpClient'
import * as AppConfig from '../../config'
import { UserRole } from '../../types/user'
import { Cart, NamedLineItems, SingleNamedlineItem } from '../../types/cart'
import { isQorpak } from '../../utils/common'

export class B2BCartRepository extends HttpClient {
  private userType?: UserRole | null

  constructor(userType?: UserRole | null) {
    super(AppConfig.API_URL)
    this.initializeRequestInterceptor()
    this.userType = userType
  }

  public getCart = async (cartId: string) => {
    const { data } = await this.instance.get<Cart>(`omni-order/carts/${cartId}`, {
      // disable caching to prevent fetching stale cart data
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    return data
  }

  public addToCart = async (
    product: {
      sku: string
      unitOfMeasure: string
      quantity: number
      variantId?: number
    },
    cartId?: string
  ) => {
    const defaultCartId = '00000000-0000-0000-0000-000000000000'
    const host = window.location.hostname
    // lower environments use v2 add to cart, while prod still uses v1 for now
    let versionedUrl = `omni-order/carts/${cartId || defaultCartId}/items`
    if (host !== 'portal.berlinpackaging.com' && host !== 'portal.qorpak.com') {
      versionedUrl = `omni-order/v2/carts/${cartId || defaultCartId}/items`
    }
    const lineItem: any = {
      sku: product.sku,
      quantity: +product.quantity,
      unitOfMeasure: product.unitOfMeasure,
    }
    if (product.variantId) {
      lineItem.variantId = product.variantId
    }
    const { data } = await this.instance.post<NamedLineItems, AxiosResponse<Cart>>(
      versionedUrl,
      {
        lineItems: [lineItem],
      },
      {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      }
    )
    return data
  }

  public removeFromCart = async (cartId: string, itemId: number | string) => {
    const { data } = await this.instance.delete<Cart>(`omni-order/carts/${cartId}/items/${itemId}`)
    return data
  }

  public updateCartItem = async (
    cartId: string,
    product: { itemId: number | string; sku: string; unitOfMeasure: string; quantity: number; variantId?: number }
  ): Promise<Cart> => {
    const host = window.location.hostname
    // lower environments use v2 add to cart, while prod still uses v1 for now
    let versionedUrl = `omni-order/carts/${cartId}/items/${product.itemId}`
    if (host !== 'portal.berlinpackaging.com' && host !== 'portal.qorpak.com') {
      versionedUrl = `omni-order/v2/carts/${cartId}/items/${product.itemId}`
    }
    const lineItem: any = {
      sku: product.sku,
      quantity: product.quantity,
      unitOfMeasure: product.unitOfMeasure,
    }
    if (product.variantId) {
      lineItem.variantId = product.variantId
    }
    const response = await this.instance.put<SingleNamedlineItem>(versionedUrl, {
      lineItem,
    })
    return response.data as unknown as Cart
  }

  public updateCart = async (cartId?: string) => {
    const { data } = await this.instance.put<{ lineItems: { productId: number; quantity: number }[] }, { data: Cart }>(
      `omni-order/carts/${cartId}`
    )
    return data
  }

  private static classInstance?: B2BCartRepository

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  private handleRequest = (config: AxiosRequestConfig) => {
    config.headers!['Ocp-Apim-Subscription-Key'] = AppConfig.APIM_SUBSCRIPTION_KEY
    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }

    // for local development only - values can be
    // https://dev.shop.qorpak.com/ or https://dev.portal.berlinpackaging.com/ depending on the API usage
    if (window.location.hostname === 'localhost') {
      config.headers!['requesting-domain'] = isQorpak() ? 'https://dev.portal.qorpak.com/' : 'https://dev.portal.berlinpackaging.com/'
    }

    return config
  }

  public static getInstance(userType?: UserRole | null) {
    if (!this.classInstance) {
      this.classInstance = new B2BCartRepository(userType)
    }
    return this.classInstance
  }
}
