const InfoRoundIcon = ({ fill = 'white' }: { fill?: string }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_486_34091)">
      <path
        d="M8.00004 1.3335C4.32004 1.3335 1.33337 4.32016 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335ZM8.66671 11.3335H7.33337V7.3335H8.66671V11.3335ZM8.66671 6.00016H7.33337V4.66683H8.66671V6.00016Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_486_34091">
        <rect width="16" height="16" fill={fill} />
      </clipPath>
    </defs>
  </svg>
)
export { InfoRoundIcon }
