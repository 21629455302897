import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRole } from '~types/user'

type UserInfo = {
  firstName: string
  lastName: string
  email: string
  userId: string
  company?: string
  profileImg?: string
  newUser?: boolean
  userRoles?: string[]
}

export interface IAuthState {
  token: null | string
  role: null | UserRole
  user: UserInfo | undefined
  logoutInProgress: boolean
}

const initialState: IAuthState = {
  token: 'null',
  role: null,
  user: undefined,
  logoutInProgress: false,
}

export const authSlice = createSlice({
  name: 'authStore',
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      state.token = payload
    },
    setRole: (state, { payload }: PayloadAction<UserRole | null>) => {
      state.role = payload
    },
    setUserInfo: (state, { payload }: PayloadAction<UserInfo | undefined>) => {
      state.user = payload
    },
    setLogoutInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.logoutInProgress = payload
    },
  },
})

export const { setToken, setRole, setUserInfo, setLogoutInProgress } = authSlice.actions
export const { reducer } = authSlice
