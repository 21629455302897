import { useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { PublicClientApplication, EventType, InteractionType } from '@azure/msal-browser'
import Hotkeys, { OnKeyFun } from 'react-hot-keys'

import EmployeeMainContent from './components/EmployeeMainContent'
import CustomerMainContent from './components/CustomerMainContent'
import AuthedContent from './components/AuthedContent'
import { FeatureFlagModal } from './components/feature-flag'
import { AuthProvider, useAuth } from './contexts/auth.context'
import { b2cPolicies, loginRequest, logoutRequest } from './authConfig'
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks'
import { setLogoutInProgress, setUserInfo } from './stores/AuthStore/authSlice'
import { selectLogoutInProgress, selectRole } from './stores/AuthStore/selectors'
import { UserRole } from './types/user'
import { parseJwt, isQorpak } from './utils/common'
import { trackAccountCreateConfirmed, trackSignInConfirmed, trackUserAccountCreation, trackUserLogin } from './utils/segment'
import { getCustomerPhoto } from './stores/UserStore/actions'
import { resetState } from './stores/rootReducer'
import { getProducts } from './stores/ProductsStore/actions'
import Cookies from 'js-cookie'
import { Dialog, DialogTitle, Stack, useTheme } from '@mui/material'
import { resetErrorModal } from './stores/UiStore/uiSlice'
import { Button } from './components/common'
import SiteMaintenance from './pages/siteMaintenance'
import { selectIsSiteMaintenance } from './stores/SiteStore/selectors'
import { makeSelectFeatureFlag } from './stores/FeatureFlagStore/selector'
import { setIsSiteMaintenance } from './stores/SiteStore/siteSlice'

function UnauthedContent({ isEmployee }: { isEmployee?: boolean }) {
  const { instance } = useMsal()
  const dispatch = useAppDispatch()
  const logoutInProgress = useAppSelector(selectLogoutInProgress)

  useEffect(() => {
    // workaround to avoid login redirect to be called during logging out,
    // this causes problems for employee logout, as it automatically redirects
    // the users to the customer login flow - ignoring what was set in the logout flow
    if (!logoutInProgress) {
      if (isQorpak()) {
        instance.loginRedirect(b2cPolicies.authorities.signUpSignInQorpak as any)
      } else if (isEmployee) {
        instance.loginRedirect(b2cPolicies.authorities.signInEmployee as any)
      } else {
        instance.loginRedirect(loginRequest)
      }
    }
    dispatch(setLogoutInProgress(false))
  }, [instance])

  return null
}

function MainContent({ isEmployee }: { isEmployee?: boolean }) {
  const navigate = useNavigate()
  const { setToken } = useAuth()
  const { instance } = useMsal()
  const [tempIsEmployee, setTempIsEmployee] = useState(isEmployee)
  const [tempToken, setTempToken] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(b2cPolicies.authorities.forgotPassword as any)
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(b2cPolicies.authorities.forgotPassword as any).catch(() => {
              return
            })
          }
        }
      }

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        // segment tracking
        const email = event?.payload?.idTokenClaims?.signInName
        const userId = event?.payload?.idTokenClaims?.sub
        if (email && userId) {
          trackUserLogin(userId, email)
        }

        if (event?.payload) {
          setToken(event?.payload?.idToken)
          setTempToken(event?.payload?.idToken)
          window.localStorage.setItem('token', event?.payload?.idToken)

          const roles = parseJwt(event?.payload?.idToken).roles ?? []
          const checkEmployee = roles.some((roleStr) => roleStr.toLowerCase().startsWith('employee'))
          setTempIsEmployee(checkEmployee)
          console.log('Setting user info', event?.payload.idToken?.roles)
          dispatch(
            setUserInfo({
              firstName: event?.payload?.idTokenClaims?.given_name ?? '',
              lastName: event?.payload?.idTokenClaims?.family_name ?? '',
              email: event?.payload?.idTokenClaims?.signInName ?? '',
              profileImg: event?.payload?.idTokenClaims?.imageUrl ?? '',
              company: event?.payload?.idTokenClaims?.extension_company ?? '',
              newUser: event?.payload.idTokenClaims?.newUser,
              userId,
              userRoles: event?.payload.idTokenClaims?.roles ?? [],
            })
          )
          dispatch(getCustomerPhoto())
          dispatch(
            getProducts({
              query: '',
              userIdentifier: Cookies.get('_br_uid_2') ?? '', // userInfo?.userId ?? '',
              page: 1,
              pageLimit: 20,
              filterQuery: [],
              sortQuery: '',
              searchType: 1,
            })
          )

          if (event?.payload.idTokenClaims?.newUser) {
            // segment tracking
            if (userId) {
              trackAccountCreateConfirmed(email)
              trackUserAccountCreation(userId, {
                email: event?.payload?.idTokenClaims?.signInName ?? event?.payload?.idTokenClaims?.email ?? '',
                firstName: event?.payload?.idTokenClaims?.given_name ?? '',
                lastName: event?.payload?.idTokenClaims?.family_name ?? '',
                displayName: event?.payload?.idTokenClaims?.name ?? '',
                role: event?.payload?.idTokenClaims?.roles || [],
                companyName: event?.payload?.idTokenClaims?.extension_company || '',
                companyId: '',
              })
            }
            navigate('/onboarding')
          } else {
            trackSignInConfirmed(email)
          }

          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (event.payload.idTokenClaims.acr === b2cPolicies.names.forgotPassword) {
            window.alert('Password has been reset successfully. \nPlease sign-in with your new password')
            return instance.logout()
          }
        }
      }
    })

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [dispatch])

  return (
    <div className="App">
      <AuthenticatedTemplate>
        {tempIsEmployee && !isEmployee ? <EmployeeMainContent token={tempToken} /> : <AuthedContent />}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <UnauthedContent isEmployee={tempIsEmployee} />
      </UnauthenticatedTemplate>
    </div>
  )
}

function App({ instance }: { instance: PublicClientApplication }) {
  const { hash, pathname } = useLocation()
  const navigate = useNavigate()
  const role = useAppSelector(selectRole)
  const dispatch = useAppDispatch()
  const isSiteMaintenance = useAppSelector(selectIsSiteMaintenance)
  const isSiteMaintenanceEnabled = useAppSelector(makeSelectFeatureFlag('site-maintenance'))
  const token = hash.split('#id_token=')[1]
  const originToken = window.localStorage.getItem('token')
  const errorModal = useAppSelector((state) => state.uiReducer.errorModal)
  const theme = useTheme()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let link: any = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    if (isQorpak()) {
      link.href = 'https://cdn.berlinpackaging.com/image/upload/v1695769143/Content%20Assets/Qorpak/Logo/Qorpak-favicon.png'
      document.title = 'Qorpak | Laboratory Packaging & Supplies'
    } else {
      link.href = '/favicon.png'
      document.title = 'Berlin Packaging | The World’s Only Hybrid Packaging Supplier'
    }
  }, [])

  useEffect(() => {
    const shouldLogout = hash.split('#')?.[1] === 'logout'
    // trigger clearing state and other things that needs to be cleared
    // when the the url includes #logout
    if (shouldLogout) {
      if (instance.getActiveAccount()) {
        instance.logoutRedirect(logoutRequest)
      }
      window.localStorage.removeItem('token')
      dispatch(resetState())

      navigate('/', {
        replace: true,
      })
    }
  }, [hash])

  useEffect(() => {
    dispatch(setIsSiteMaintenance(isSiteMaintenanceEnabled))
  }, [isSiteMaintenanceEnabled])

  const isEmployee = useMemo(() => {
    if (!token) return false

    const roles = parseJwt(token).roles ?? []
    return roles.some((roleStr) => roleStr.toLowerCase().startsWith('employee'))
  }, [token])

  const [showFeatureFlagModal, setShowFeatureFlagModal] = useState(false)

  const handleHotkeyDown: OnKeyFun = (keyName) => {
    if (keyName === 'ctrl+e') {
      setShowFeatureFlagModal(true)
    }
  }

  return (
    <MsalProvider instance={instance}>
      <Hotkeys keyName="ctrl+e" onKeyDown={handleHotkeyDown} />

      {isSiteMaintenance ? (
        <SiteMaintenance />
      ) : (
        <AuthProvider>
          {pathname.includes('/employee/login') ? (
            <MainContent isEmployee={pathname.includes('/employee/login')} />
          ) : (!!token && !isEmployee) || (role === UserRole.CUSTOMER && originToken) ? (
            <CustomerMainContent token={token ?? originToken} />
          ) : isEmployee || (role === UserRole.EMPLOYEE && originToken) ? (
            <EmployeeMainContent token={token ?? originToken} />
          ) : (
            <MainContent isEmployee={pathname.includes('/employee/login')} />
          )}
        </AuthProvider>
      )}

      <FeatureFlagModal open={showFeatureFlagModal} onClose={() => setShowFeatureFlagModal(false)} />

      {/* General error */}
      <Dialog onClose={() => dispatch(resetErrorModal())} open={!!errorModal}>
        <DialogTitle sx={{ fontSize: 16 }}>{errorModal?.message}</DialogTitle>
        <Stack direction="row" sx={{ alignSelf: 'flex-start', justifyContent: 'flex-end', px: 3, pb: 3, width: '100%' }} spacing={1}>
          <Button
            variant="contained"
            rounded={true}
            label="Close"
            sx={{
              background: theme.palette.common.darkBlue,
              '&:hover': {
                background: theme.palette.common.blue,
                opacity: 0.9,
              },
            }}
            onClick={() => {
              dispatch(resetErrorModal())
            }}
          ></Button>
        </Stack>
      </Dialog>
    </MsalProvider>
  )
}

export default App
