import { Stack, Checkbox as MuiCheckbox, FormControlLabel, useTheme, Tooltip } from '@mui/material'
import { get } from 'lodash'

import { Modal } from '../common'

import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks'
import { selectFeatureFlags } from '../../stores/FeatureFlagStore/selector'
import { toggleFeature } from '../../stores/FeatureFlagStore/featureFlagSlice'

import { registeredFeatureFlags } from './constants'

import { OmniChannelFeature } from '../../types/common'

type FeatureFlagModalProps = {
  open: boolean
  onClose: () => void
}

export const FeatureFlagModal = ({ open, onClose }: FeatureFlagModalProps) => {
  const dispatch = useAppDispatch()
  const featureFlags = useAppSelector(selectFeatureFlags)

  const theme = useTheme()

  const handleFlagChange = (feature: OmniChannelFeature) => () => {
    dispatch(toggleFeature(feature))
  }

  return (
    <Modal open={open} title="Manage OmniChannel Features" titleAlign="start" maxHeight="80vh" actions={() => null} onClose={onClose}>
      <Stack sx={{ borderRadius: 0 }} spacing={1.375}>
        {registeredFeatureFlags.map((featureFlag) => (
          <Tooltip title={featureFlag.description ?? ''} key={featureFlag.feature} placement="top">
            <FormControlLabel
              key={featureFlag.feature}
              value={featureFlag.feature}
              control={
                <MuiCheckbox
                  name=""
                  checked={get(featureFlags, featureFlag.feature)}
                  color="secondary"
                  value={featureFlag.feature}
                  onChange={handleFlagChange(featureFlag.feature)}
                />
              }
              label={featureFlag.name}
              labelPlacement="end"
              sx={{
                m: 0,
                gap: 1,
                '.MuiFormControlLabel-label': {
                  fontSize: 16,
                  lineHeight: '19px',
                  fontWeight: '400',
                  color: theme.palette.common.black,
                },
                '.MuiCheckbox-root': {
                  p: 0,
                  color: theme.palette.common.gray600,
                },
                '.Mui-checked': {
                  color: `${theme.palette.common.blue} !important`,
                },
              }}
            />
          </Tooltip>
        ))}
      </Stack>
    </Modal>
  )
}
