const BoxIcon = ({ color = '#231F20' }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <g clipPath="url(#clip0_1664_1998)">
      <path
        d="M10.0002 3L16.6668 6.75V14.25L10.0002 18L3.3335 14.25V6.75L10.0002 3Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 10.5L16.6667 6.75" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10.5V18" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0002 10.5L3.3335 6.75" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3332 4.875L6.6665 8.625" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1664_1998">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export { BoxIcon }
