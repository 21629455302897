import { IconUserPlus } from '@tabler/icons'

const userManagement = {
  id: 'user-management',
  title: 'User Management',
  type: 'item',
  url: 'employee/user-management',
  icon: IconUserPlus,
  breadcrumbs: false,
}

export default userManagement
