import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { getPixels } from '../../stores/BloomReachStore/actions'
import { selectPixels } from '../../stores/BloomReachStore/selectors'
import { isProduction } from '../../utils/common'

export const BloomReach = () => {
  const dispatch = useAppDispatch()
  const pixelInfo = useAppSelector(selectPixels)

  useEffect(() => {
    if (pixelInfo.length === 0) {
      dispatch(getPixels())
    }
  }, [])

  useEffect(() => {
    if (pixelInfo) {
      const brtrk = document.createElement('script')
      brtrk.type = 'text/javascript'
      brtrk.async = true
      brtrk.src = `//cdn.brcdn.com/v1/br-trk-${pixelInfo.accountId}.js`
      document.body.appendChild(brtrk)
    }
  }, [pixelInfo])
  return <></>
}

export const RegisterPixel = (brObj: any) => {
  const brData: any = {}
  brData.title = document.title
  brData.orig_ref_url = location.href
  if (!isProduction()) {
    brData.test_data = true
  }
  window?.BrTrk?.getTracker().updateBrData({ ...brData, ...brObj })
  window?.BrTrk?.getTracker().logPageView()
}
