import { lightModeColors, darkModeColors, qorpakLightModeColors, qorpakDarkModeColors } from './colors'
import { isQorpak } from '../utils/common'

/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme: any) {
  let colorTheme = lightModeColors
  let siteTheme = 'berlin'
  if (isQorpak()) {
    siteTheme = 'qorpak'
    if (theme?.customization?.mode === 'dark') {
      colorTheme = qorpakDarkModeColors
    } else {
      colorTheme = qorpakLightModeColors
    }
  } else {
    if (theme?.customization?.mode === 'dark') {
      colorTheme = darkModeColors
    }
  }
  return {
    theme: siteTheme,
    mode: theme?.customization?.mode,
    common: {
      ...colorTheme,
      ...(siteTheme === 'qorpak'
        ? {
            lightBg: theme.colors?.lightBgQorpak,
          }
        : {
            lightBg: theme.colors?.lightBg,
          }),
    },
    ...(siteTheme === 'qorpak'
      ? {
          primary: {
            light: theme.colors?.primaryWhiteQorpak,
            main: theme.colors?.primaryMainQorpak,
            dark: theme.colors?.primaryDarkQorpak,
            200: theme.colors?.primary200Qorpak,
            800: theme.colors?.primary800Qorpak,
          },
        }
      : {
          primary: {
            light: theme.colors?.primaryLight,
            main: theme.colors?.primaryMain,
            dark: theme.colors?.primaryDark,
            200: theme.colors?.primary200,
            800: theme.colors?.primary800,
          },
        }),
    ...(theme?.customization?.mode === 'dark'
      ? {
          primary: {
            light: theme.colors?.darkPrimaryLight,
            main: theme.colors?.darkPrimaryMain,
            dark: theme.colors?.darkPrimaryDark,
            200: theme.colors?.darkPrimary200,
            800: theme.colors?.darkPrimary800,
          },
          secondary: {
            light: theme.colors?.darkSecondaryLight,
            main: theme.colors?.darkSecondaryMain,
            dark: theme.colors?.darkSecondaryDark,
            200: theme.colors?.darkSecondary200,
            800: theme.colors?.darkSecondary800,
          },
        }
      : {
          secondary: {
            light: theme.colors?.secondaryLight,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800,
            ...(siteTheme === 'qorpak'
              ? {
                  main: theme.colors?.secondaryMainQorpak,
                }
              : {
                  main: theme.colors?.secondaryMain,
                }),
          },
          error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark,
          },
          orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark,
          },
          warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark,
          },
          success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark,
          },
          grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark,
          },
          dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper,
          },
          text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100,
          },
          background: {
            paper: theme.paper,
            default: theme.backgroundDefault,
          },
        }),
  }
}
