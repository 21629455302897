import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  pixels: [],
}

export const bloomReachSlice = createSlice({
  name: 'bloomReachStore',
  initialState,
  reducers: {
    setPixels: (state, { payload }: PayloadAction<any>) => {
      state.pixels = payload
    },
  },
})

export const { setPixels } = bloomReachSlice.actions
export const { reducer } = bloomReachSlice
