import { lazy, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// material-ui
import { styled, Theme, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'

// project imports
import { IconChevronRight } from '@tabler/icons'

import Header from './Header'
import Footer from './Footer'

import { isQorpak } from '../../utils/common'
import { getCart } from '../../stores/b2b/CartStore/actions'
import { Breadcrumbs } from '../../components/common'
import { useMenuItems } from '../../menu-items'

// assets

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { selectMenuState } from '../../stores/UiStore/selectors'
import { setMenuState } from '../../stores/UiStore/uiSlice'
import { selectUser } from '../../stores/AuthStore/selectors'
import { B2BCartDrawer } from '../../components/cart/b2b/CartDrawer/CartDrawer'
import { B2CCartDrawer } from '../../components/cart/b2c/CartDrawer/CartDrawer'
import { BloomReach } from '../../components/BloomReach'
import Loadable from '../../components/Loadable'
import { selectIsCrossBrandUser } from '../../stores/UserStore/selectors'

// b2b
import { selectCartIsOpen as b2bSelectCartIsOpen } from '../../stores/b2b/CartStore/selectors'
import { closeCartDrawer as b2bCloseCartDrawer } from '../../stores/b2b/CartStore/cartSlice'

// b2c
import { selectCartIsOpen as b2cSelectCartIsOpen } from '../../stores/b2c/CartStore/selectors'
import { closeCartDrawer as b2cCloseCartDrawer } from '../../stores/b2c/CartStore/cartSlice'
import { selectProducts } from '../../stores/ProductsStore/selectors'
import { ProductMinimumModal } from '../../components/modals/ProductMinimumModal'

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ theme: Theme; open: boolean }>(({ theme, open }) => ({
  ...(theme.typography as any).mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      marginLeft: '8px',
      width: '100%',
      minHeight: 'calc(100vh - 243px)',
      padding: '16px',
      marginRight: '8px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }),
}))

// pages
const CrossBrandUser = Loadable(lazy(() => import('../../pages/cross-brand-user')))

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))
  const { items: navigation } = useMenuItems()
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const isB2BCheckoutEnabled = user?.userRoles?.includes('Customer B2B Checkout')
  const b2bCartIsOpen = useAppSelector(b2bSelectCartIsOpen)
  const b2cCartIsOpen = useAppSelector(b2cSelectCartIsOpen)
  const cartIsOpen = isB2BCheckoutEnabled ? b2bCartIsOpen : b2cCartIsOpen
  const isProductDetailEnabled =
    user?.userRoles?.includes('Customer Catalog Image Update') ||
    user?.userRoles?.includes('Customer B2B Catalog') ||
    user?.userRoles?.includes('Customer B2B Checkout')
  const { products } = useAppSelector(selectProducts)
  const shouldDisplayProducts = isProductDetailEnabled && products.length
  const platform = isQorpak() ? 'qorpak' : 'berlin'
  const isCrossBrandUser = useAppSelector(selectIsCrossBrandUser)

  // Handle left drawer
  const leftDrawerOpened = useAppSelector(selectMenuState)

  useEffect(() => {
    dispatch(setMenuState(!matchDownMd))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd])

  const isCheckout = window.location.href?.includes('checkout')

  useEffect(() => {
    if (!isCheckout) {
      dispatch(getCart())
    }
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', background: theme.palette.common.lightGray, overflowX: 'clip' }}>
        <CssBaseline />
        <BloomReach />
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            p: 0,
            borderBottom: `1px solid ${theme.palette.common.gray600}`,
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
            '& .MuiToolbar-root': { p: 0 },
          }}
        >
          <Toolbar>
            <Header shouldDisplayProducts={Boolean(shouldDisplayProducts)} />
          </Toolbar>
        </AppBar>

        {/* product min quantity */}
        <ProductMinimumModal />

        {/* drawer */}
        {isB2BCheckoutEnabled ? (
          <B2BCartDrawer
            open={cartIsOpen}
            onToggle={() => {
              dispatch(b2bCloseCartDrawer())
            }}
          ></B2BCartDrawer>
        ) : (
          <B2CCartDrawer
            open={cartIsOpen}
            onToggle={() => {
              dispatch(b2cCloseCartDrawer())
            }}
          ></B2CCartDrawer>
        )}

        {isCrossBrandUser ? (
          <CrossBrandUser />
        ) : (
          <>
            {/* main content */}
            <Main theme={theme} open={false} sx={{ background: theme.palette.common.lightGray, mt: { xs: '100px', md: 20 } }}>
              {/* breadcrumb */}
              <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
              <Outlet />
            </Main>
          </>
        )}
      </Box>
      <Footer platform={platform} />
    </>
  )
}

export default MainLayout
