import type { RootState } from '../../rootReducer'

export const selectCartItems = ({ b2cCartReducer }: RootState) => b2cCartReducer.cartItems
export const selectSavedForLaterItems = ({ b2cCartReducer }: RootState) => b2cCartReducer.saveForLaterItems
export const selectRecommendedItems = ({ b2cCartReducer }: RootState) => b2cCartReducer.recommendedItems
export const selectPerfectMatchItems = ({ b2cCartReducer }: RootState) => b2cCartReducer.perfectMatchItems

export const selectCartIsOpen = ({ b2cCartReducer }: RootState) => b2cCartReducer.cartDrawerIsOpen
export const selectEmailCartModalIsOpen = ({ b2cCartReducer }: RootState) => b2cCartReducer.emailCartModalIsOpen
export const selectEstimateShippingModalIsOpen = ({ b2cCartReducer }: RootState) => b2cCartReducer.estimateShippingModalIsOpen
export const selectTaxExemptModalIsOpen = ({ b2cCartReducer }: RootState) => b2cCartReducer.taxExemptModalIsOpen
