import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { WishlistBasic } from '../../types/wishlist'

export interface WishlistState {
  wishlistList: WishlistBasic[]
}

const initialState: WishlistState = {
  wishlistList: [],
}

export const wishlistSlice = createSlice({
  name: 'wishlistStore',
  initialState,
  reducers: {
    setWishlists: (state, { payload }: PayloadAction<WishlistBasic[]>) => {
      state.wishlistList = payload
    },
  },
})

export const { setWishlists } = wishlistSlice.actions
export const { reducer } = wishlistSlice
