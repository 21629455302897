import { get } from 'lodash'

import { RootState } from '../rootReducer'
import { OmniChannelFeature } from '../../types/common'

export const makeSelectFeatureFlag =
  (feature: OmniChannelFeature) =>
  ({ featureFlagReducer }: RootState) =>
    get(featureFlagReducer.featureFlags, feature)

export const selectFeatureFlags = ({ featureFlagReducer }: RootState) => featureFlagReducer.featureFlags
