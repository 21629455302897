import { OmniChannelFeature } from '../../types/common'

type OmniChannelFeatureFlag = {
  feature: OmniChannelFeature
  name: string
  description?: string
}

export const registeredFeatureFlags: OmniChannelFeatureFlag[] = [
  // disabling this until we plan to support it in the future
  // {
  //   feature: 'dark-mode',
  //   name: 'Dark/Light Mode',
  //   description: 'Toggles theme management feature, which user can use to switch dark/light modes.',
  // },
  {
    feature: 'profile-uploader',
    name: 'Profile Picture Uploader (Requires Browser Reload After Changing)',
    description: 'Toggles user profile picture upload screen in onboarding flow. Please reload browser after changing this flag.',
  },
  {
    feature: 'wishlist',
    name: 'Wishlist',
    description: 'Toggles wishlist page where user can manage his/her wishlists. It also enables/disables left navigation bar.',
  },
  // {
  //   feature: 'mock-data',
  //   name: 'Mock Data UI (Requires Browser Reload After Changing)',
  //   description: 'Toggles mock data presentation in UI. Please reload browser after changing this flag.',
  // },
  // {
  //   feature: 'product-detail',
  //   name: 'Private Catalog (Requires Browser Reload After Changing)',
  //   description: 'Toggles between allowing users to navigate to product pages or not.',
  // },
  // {
  //   feature: 'checkout',
  //   name: 'Checkout (Requires Browser Reload After Changing)',
  //   description: 'Toggles between allowing users to access checkout functionality or not.',
  // },
  // {
  //   feature: 'b2b-checkout',
  //   name: 'Checkout (Requires Browser Reload After Changing)',
  //   description: 'Toggles between B2B Checkout and B2C Checkout flows',
  // },
  {
    feature: 'header-searchbar',
    name: 'Header Searchbar',
    description: 'Toggles product searchbar in header.',
  },
  // {
  //   feature: 'update-product-image',
  //   name: 'Update Product Image',
  //   description: 'Updates product image in PLP',
  // },
  {
    feature: 'checkout-add-address',
    name: 'Add Address',
    description: 'Toggles add shipping and billing address functionaity in checkout flow.',
  },

  {
    feature: 'site-maintenance',
    name: 'Site Maintenance',
    description: 'Toggles site maintenance page.',
  },
]
