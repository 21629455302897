import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  IProductSimple,
  ProductDetail,
  ProductPrice,
  IFacetField,
  ProductImage,
  ProductFilters,
  ProductListViewMode,
} from '../../types/product'

export interface ProductsState {
  productsList: IProductSimple[]
  facetFields: Record<string, IFacetField[]>
  total: number
  currentProduct:
    | {
        details: ProductDetail | undefined
        prices: ProductPrice[] | undefined
      }
    | undefined
  productInventory?: any
  // productInventoryByLocation?: any
  plpLoader: boolean
  uploadedImg: ProductImage | undefined
  itemsPerPage?: number
  productFilters: ProductFilters
  viewMode?: ProductListViewMode

  displayMinQtyModal?: {
    message: string
  } | null
}

const initialState: ProductsState = {
  productsList: [],
  facetFields: {},
  total: 0,
  currentProduct: undefined,
  productInventory: {},
  plpLoader: false,
  uploadedImg: undefined,
  productFilters: { filters: {}, searchString: '', page: 1, perPage: 12, sortBy: 'NONE' },
  viewMode: 'list',
}

export const productsSlice = createSlice({
  name: 'productsStore',
  initialState,
  reducers: {
    setProducts: (
      state,
      {
        payload,
      }: PayloadAction<{ products: IProductSimple[]; total: number; facetFields: Record<string, IFacetField[]>; hasFilterQuery: boolean }>
    ) => {
      state.productsList = payload.products
      state.total = payload.total
      // don't update facefields if a filterQuery is present
      // as it returns an incomplete list of available filters
      if (!payload.hasFilterQuery) {
        state.facetFields = payload.facetFields
      }
    },
    resetProducts: (state, { payload }: PayloadAction<{ skipClearFacetFields?: boolean }>) => {
      state.productsList = []
      if (!payload.skipClearFacetFields) {
        state.facetFields = {}
      }
      state.total = 0
    },
    setProduct: (state, { payload }: PayloadAction<ProductDetail | undefined>) => {
      state.currentProduct = {
        prices: state.currentProduct?.prices,
        details: payload,
      }
    },
    setProductFilters: (state, { payload }: PayloadAction<ProductFilters>) => {
      state.productFilters = payload
    },
    setProductPrices: (state, { payload }: PayloadAction<ProductPrice[] | undefined>) => {
      state.currentProduct = {
        details: state.currentProduct?.details,
        prices: payload,
      }
    },
    setPLPLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.plpLoader = payload
    },
    setUploadedImage: (state, { payload }: PayloadAction<ProductImage | undefined>) => {
      state.uploadedImg = payload
    },
    setItemsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.itemsPerPage = payload
    },
    setViewMode: (state, { payload }: PayloadAction<ProductListViewMode>) => {
      state.viewMode = payload
    },
    setMinQtyModal: (state, { payload }: PayloadAction<{ message: string } | null>) => {
      state.displayMinQtyModal = payload
    },
  },
})

export const {
  setProducts,
  resetProducts,
  setProduct,
  setProductPrices,
  setPLPLoader,
  setItemsPerPage,
  setUploadedImage,
  setProductFilters,
  setViewMode,
  setMinQtyModal,
} = productsSlice.actions
export const { reducer } = productsSlice
