import type { RootState } from '../../rootReducer'

export const selectSavedAddress = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.savedAddress
export const selectShipping = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.shipping
export const selectTax = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.tax
export const selectDiscount = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.discount
export const selectPurchaseOrderNumber = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.purchaseOrderNumber
export const selectDeliveryMethod = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.deliveryMethod

export const selectAddNewAddressModalIsOpen = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.addNewAddressModalIsOpen
export const selectEditAddressModalIsOpen = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.editAddressModalIsOpen
export const selectSelectShippingModalIsOpen = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.selectShippingMethodIsOpen
export const selectAddDeliveryNotesModalIsOpen = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.addDeliveryNotesModalIsOpen

export const selectTaxExemptSubmissionResp = ({ b2cCheckoutReducer }: RootState) => b2cCheckoutReducer.taxExemptSubmissionResp
