import { Box, CssBaseline, Grid, StyledEngineProvider, ThemeProvider, Typography } from '@mui/material'
import LogoSection from '../../layout/MainLayout/LogoSection'
import { useAppSelector } from '../../hooks/reduxHooks'
import { selectUiState } from '../../stores/UiStore/selectors'
import themes from '../../theme'
import { isQorpak } from '../../utils/common'

const SiteMaintenance = () => {
  const customization = useAppSelector(selectUiState)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', color: 'black' }}>
          <Box sx={{ padding: '10px 20px' }}>
            <LogoSection />
          </Box>
          <Box sx={{ backgroundColor: '#F7F9FB', height: '100%' }}>
            <Grid container height={'100%'}>
              <Grid item xs={12} sm={6} alignContent={'center'} padding={'0 70px'}>
                <Typography variant="h1" fontWeight={400} padding={'10px 0'}>
                  We’ll be back soon!
                </Typography>
                <Typography component="p" fontWeight={500}>
                  We are performing scheduled maintenance on the site to better improve the user experience. We’re sorry for any
                  inconvenience this may have caused.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} alignContent={'center'}>
                <img
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                  src="https://cdn.berlinpackaging.com/image/upload/v1722976395/Content%20Assets/SiteM-01.png"
                  alt="site-maintenance"
                ></img>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, margin: '10px 20px' }}>
            <Typography component="p" fontWeight={400}>
              {`© 2024 Berlin Packaging${isQorpak() ? ' | Qorpak.' : '.'} All Rights Reserved.`}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export { SiteMaintenance }
