const ArrowForwardIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.22852 16.0703L6.80352 14.6703L12.4035 9.07031H0.228516V7.07031H12.4035L6.80352 1.47031L8.22852 0.0703125L16.2285 8.07031L8.22852 16.0703Z"
      fill="#1C1B1F"
    />
  </svg>
)

export { ArrowForwardIcon }
