import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles'

// assets

// config

// project imports
import componentStyleOverrides from './compStyleOverride'
import themePalette from './palette'
import themeTypography from './typography'

import config from '../config'
import colors from '../assets/scss/_themes-vars.module.scss'

import '@mui/material/styles/createPalette'
declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    red: string
    darkRed: string
    lightRed: string
    white: string
    black: string
    darkGreen: string
    lightGreen: string
    darkBlue: string
    blue: string
    softBlue: string
    lightBlue: string
    altBlue: string
    altLightBlue: string
    contrastBlue: string
    cyanBlue: string
    artboard: string
    text: string
    textSecondary: string
    darkGray?: string
    lightGray?: string
    yellow: string
    lightYellow?: string
    darkYellow: string
    primaryBlue?: string
    labelBgBlue?: string
    gray300?: string
    gray600?: string
    gray700?: string
    gray900?: string
    qorpakGreen: string
    blue700?: string
    blue900?: string
    qorpakSecondary?: string
    green700?: string
    lightBg: string
    borderLightGray: string
    borderGray: string
  }
}

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization: any) => {
  const color = colors
  const fontFamily = config.fontFamily

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.white,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: customization?.mode === 'dark' ? color.black : color.secondaryDark,
    menuSelectedBack: customization?.mode === 'dark' ? color.darkSecondary800 : color.secondary800,
    divider: color.grey200,
    customization: {
      ...customization,
      fontFamily: fontFamily,
    },
  }

  const themeOptions: ThemeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    // shadows: {

    // },
    typography: themeTypography(themeOption),
  }

  let themes = createTheme(themeOptions)
  themes.components = componentStyleOverrides(themeOption)

  themes = responsiveFontSizes(themes)

  return themes
}

export default theme
