import { IconCirclePlus, IconCircleMinus } from '@tabler/icons'

const closeSideBar = {
  id: 'close',
  title: 'Close Menu',
  type: 'item',
  url: '',
  icon: IconCirclePlus,
  altIcon: IconCircleMinus,
  breadcrumbs: false,
}

export default closeSideBar
