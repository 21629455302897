import { ErrorInfo } from 'react'
import { Box, Typography, Button } from '@mui/material'

export interface ErrorComponentProps {
  error: Error | null
  errorInfo: ErrorInfo
  onReset(): void
}

/**
 * Describe your component here
 */
export function ErrorComponent(props: ErrorComponentProps) {
  const { error, errorInfo, onReset } = props

  return (
    <div>
      <Box alignItems="center" display="flex" flexDirection="column" padding={4}>
        <h1>We are sorry!{'\n'} Something went wrong</h1>
        <Box height={16} />
        <Typography>Info: {errorInfo.componentStack}</Typography>
        <Typography>{String(error)}</Typography>
        <Box height={8} />
        <Button onClick={onReset}>Reset</Button>
      </Box>
    </div>
  )
}
