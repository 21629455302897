export const RuleIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_302_6841)">
      <path
        d="M33.08 22.0001L26 14.9201L28.82 12.1001L33.06 16.3401L41.54 7.86011L44.36 10.6801L33.08 22.0001ZM22 14.0001H4V18.0001H22V14.0001ZM42 26.8201L39.18 24.0001L34 29.1801L28.82 24.0001L26 26.8201L31.18 32.0001L26 37.1801L28.82 40.0001L34 34.8201L39.18 40.0001L42 37.1801L36.82 32.0001L42 26.8201ZM22 30.0001H4V34.0001H22V30.0001Z"
        fill="#DBEFFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_302_6841">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
