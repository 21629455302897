import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import { Box, Chip, Grid, Stack, TextField, useTheme } from '@mui/material'

import { Typography, Button } from '../../../common'
import { InfoRoundIcon, ShoppingCartIcon } from '../../../icons'
import { useTranslation } from 'react-i18next'
import { IProductSimple } from '../../../../types/product'
import { useAppDispatch } from '../../../../hooks/reduxHooks'
import { addToCart } from '../../../../stores/b2c/CartStore/cartSlice'

type Props = {
  data: IProductSimple & { quantity?: number }
  size: 'small' | 'default'
}

const B2CCartMatchItem = React.memo(({ data, size }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [quantity, setQuantity] = useState(data.quantity || 0)

  useEffect(() => {
    if (data.quantity) {
      setQuantity(data.quantity)
    }
  }, [data])

  const onAddToCart = () => {
    dispatch(
      addToCart({
        ...data,
        quantity: quantity,
      })
    )
    window?.BrTrk?.getTracker().logEvent('cart', 'click-add', { prod_id: data.properties.sku, sku: data.properties.sku, test_data: true })
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuantity(+evt.target.value)
  }
  return (
    <Grid container spacing={2} p={3}>
      <Grid item xs={size === 'small' ? 3 : 2}>
        <img src={data.properties.thumbnail} alt={data.properties.name} style={{ width: '100%' }} />
      </Grid>
      <Grid container item xs={size === 'small' ? 9 : 10} spacing={1}>
        <Grid item xs={size === 'small' ? 9 : 6}>
          <Stack spacing={1}>
            <Stack>
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                {data.properties.description}
              </Typography>
              <Typography sx={{ fontSize: 16 }}>
                {t('cart.product_card.item_number')} #{data.id}
              </Typography>
            </Stack>
            <Stack display={size === 'small' ? 'none' : 'flex'}>
              {/* <Typography sx={{ fontSize: 16 }}>
                {t('cart.product_card.capacity')}: {data.capacity}
              </Typography>
              {data.quantitySize && (
                <Typography sx={{ fontSize: 16 }}>
                  {t('cart.product_card.quantity_size')}: {data.quantitySize}
                </Typography>
              )}
              <Typography sx={{ fontSize: 16 }}>
                {t('cart.product_card.total_units')}: {data.totalUnits}
              </Typography> */}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2} display={size === 'small' ? 'none' : 'flex'}>
          <TextField id="outlined-basic" type="number" variant="outlined" name="quantity" value={quantity} onChange={handleChange} />
        </Grid>
        <Grid item xs={2} display={size === 'small' ? 'none' : 'flex'} justifyContent="flex-end">
          <Typography variant="h4">{numeral(data.properties.retailPrice).format('$0,0.00')}</Typography>
        </Grid>
        <Grid item xs={2} display={size === 'small' ? 'none' : 'flex'} justifyContent="flex-end">
          <Typography variant="h4">{numeral(data.properties.retailPrice * quantity).format('$0,0.00')}</Typography>
        </Grid>

        <Grid item xs={3} display={size === 'small' ? 'flex' : 'none'}>
          <Stack spacing={0.5} alignItems="flex-end">
            <Typography variant="h4" sx={{ color: theme.palette.common.darkGreen }}>
              {numeral(data.properties.retailPrice * quantity).format('$0,0.00')}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              {numeral(data.properties.retailPrice).format('$0,0.00')}/{t('cart.product_card.each')}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} display={size === 'small' ? 'none' : 'flex'}>
          <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
            {/* statuses - this will be rendered depending on status flags on the product data */}
            <Chip
              label={t('cart.product_card.status.ships_within_3_to_5_business_days')}
              sx={{ borderRadius: 0, background: theme.palette.common.green700, color: theme.palette.common.darkGreen }}
            />
            <Chip
              label={t('cart.product_card.status.limited_stock')}
              sx={{ borderRadius: 0, background: theme.palette.common.gray700, color: theme.palette.common.darkGray }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} display={size === 'small' ? 'none' : 'flex'}>
          <Stack direction="row" alignItems="center">
            {/* alert - this will be rendered depending on a flag on the product data */}
            <Chip
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Chip
                    label={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography sx={{ fontWeight: 'bold' }}>{t('cart.product_card.alerts.ship_as_available.title')}</Typography>
                        <InfoRoundIcon />
                      </Stack>
                    }
                    sx={{ borderRadius: 1, background: theme.palette.common.darkBlue, color: theme.palette.common.white, height: '26px' }}
                  />
                  <Typography sx={{ pr: 1 }}>{t('cart.product_card.alerts.ship_as_available.description')}</Typography>
                </Stack>
              }
              sx={{
                borderRadius: 1,
                background: theme.palette.common.blue700,
                color: theme.palette.common.darkBlue,
                '> .MuiChip-label': {
                  padding: 0.5,
                },
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} display={size === 'small' ? 'flex' : 'none'}>
          <Box flexGrow={1}>
            <TextField
              fullWidth
              label={t('cart.product_card.quantity')}
              inputProps={{
                'aria-label': t('cart.product_card.quantity'),
              }}
              InputProps={{ sx: { borderRadius: 0 } }}
              value={quantity}
              onChange={handleChange}
            />
          </Box>
          <Button
            variant="contained"
            label={<ShoppingCartIcon color={theme.palette.common.white} />}
            sx={{ background: theme.palette.common.darkBlue }}
            onClick={onAddToCart}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})

export { B2CCartMatchItem }
