import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton, Stack, TextField, useTheme } from '@mui/material'
import { Typography, Button } from '../../../common'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import { removeFromCart, updateCartItem } from '../../../../stores/b2b/CartStore/actions'
import { PhysicalItem } from '../../../../types/cart'
import { CheckoutPhysicalItem, OrderConfirmationLineItem } from '../../../../types/checkout'
import { trackRemoveProductFromCart } from '../../../../utils/segment'
import { selectProductCategoryMap } from '../../../../stores/b2b/CartStore/selectors'
import { removeFromProductCategoryMap } from '../../../../stores/b2b/CartStore/cartSlice'
import { getImageUrl, maxAddToCartQuantity } from '../../../../utils/common'
import numeral from 'numeral'

type Props = {
  data: PhysicalItem | CheckoutPhysicalItem | OrderConfirmationLineItem
  size: 'small' | 'default'
  parent: string // for Segment to track cart operations' source
  editDisabled?: boolean
  ordered?: boolean
  readonly?: boolean
  displayPricing?: boolean
}

const B2BCartItem = React.memo(({ data, editDisabled, size = 'default', ordered, parent, readonly, displayPricing }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const productCategoryMap = useAppSelector(selectProductCategoryMap)
  const skuLoading = useAppSelector((state) => state.b2bCartReducer.skuLoading)
  const [cartItemIsLoading, setCartItemIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [quantity, setQuantity] = useState(data.quantity)
  const [uom, setUom] = useState<{ identifier: string | null; quantity: number; name: string } | undefined>(undefined)

  useEffect(() => {
    if ('id' in data && skuLoading === data.id) {
      setCartItemIsLoading(true)
    } else {
      setCartItemIsLoading(false)
    }
  }, [data, skuLoading])

  useEffect(() => {
    if ('soldAs' in data && !!data.soldAs.name) {
      setUom(data.soldAs)
    } else if ('unitOfMeasure' in data && !!data.unitOfMeasure.name) {
      setUom(data.unitOfMeasure)
    } else {
      setUom(undefined)
    }
  }, [data])

  const remove = () => {
    if (!ordered && 'id' in data) {
      dispatch(removeFromCart(data.id))

      // Segment tracking
      trackRemoveProductFromCart(data, parent, productCategoryMap?.[data.sku] ?? '')
      dispatch(removeFromProductCategoryMap({ sku: data.sku }))
    }
  }

  useEffect(() => {
    setQuantity(data.quantity)
  }, [data])

  useEffect(() => {
    if (!quantity) {
      setErrorMessage('Quantity cannot be empty')
      return
    } else if ('minimumPurchaseQuantity' in data && +quantity < data.minimumPurchaseQuantity) {
      // display message for not meeting min quantity
      setErrorMessage("The item cannot be updated because there isn't valid pricing for the quantity requested.")
      return
    } else if ('maximumPurchaseQuantity' in data && +quantity > data.maximumPurchaseQuantity) {
      // display message for exceeding max quantity
      setErrorMessage("The item cannot be updated because there isn't valid pricing for the quantity requested.")
      return
    } else {
      setErrorMessage('')
    }
    if (data.quantity === quantity) {
      // skip sending data if API and Client are already in sync
      return
    }
    // add debounce here to prevent triggering the API call on every keystroke
    const timeoutId = setTimeout(() => {
      if (!ordered && 'id' in data) {
        dispatch(
          updateCartItem({
            itemId: data.id,
            sku: data.sku,
            unitOfMeasure: data.soldAs.identifier ?? '',
            quantity: quantity,
            variantId: data.variantId,
          })
        )
      }
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [quantity])

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (evt.target.value !== '0') {
      const qty = evt.target.value
      setQuantity(parseInt(qty))
    }
  }

  const thumbnail = getImageUrl(data.imageUrl, 100, true)
  const descriptionSx = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '120%',
    color: '#616161', //theme.palette.common.gray700
  }
  const pricingSx = {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '140%',
    color: theme.palette.common.black,
    textAlign: 'right',
  }
  return (
    <Grid container spacing={2}>
      <Grid container item xs={size === 'default' ? 8 : 12} spacing="16px">
        <Grid container item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
            }}
          >
            <Box
              sx={{
                width: '100px',
                flexShrink: 0,
                flexGrow: 0,
              }}
            >
              <img
                src={thumbnail}
                alt={data.name}
                style={{ width: '100%', maxHeight: '150px', objectFit: 'contain', height: '100%', objectPosition: 'center top' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Grid container item xs={10} spacing={1}>
                <Grid item xs={12}>
                  <Stack>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        color: theme.palette.common.black,
                        lineHeight: '140%',
                        mb: 0.5,
                      }}
                    >
                      {data.name}
                    </Typography>
                    <Typography sx={descriptionSx}>
                      {t('product.berlin_item')}
                      {data.sku}
                      {data?.unitOfMeasure?.identifier && `-${data?.unitOfMeasure?.identifier}`}
                    </Typography>
                    {data.alias ? (
                      <Typography sx={descriptionSx}>
                        {t('product.your_item')}
                        {data.alias}
                      </Typography>
                    ) : (
                      ''
                    )}
                    {size === 'small' && (
                      <>
                        <Typography sx={descriptionSx}>
                          {t('product.sold_as')}:{' '}
                          {uom ? `${uom.name} (${uom.quantity.toLocaleString('en-US')} ${uom.quantity > 1 ? 'pcs' : 'pc'})` : 'TBD'}
                        </Typography>
                        <Typography
                          sx={{
                            ...descriptionSx,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {data.quantity * (uom?.quantity ?? 1) > 1 ? t('product.total_eaches') : t('product.total_each')}:{' '}
                          <>
                            {cartItemIsLoading ? (
                              <Skeleton variant="rectangular" width="50px" height="19.2px" sx={{ ml: '8px' }} />
                            ) : (
                              (data.quantity * (uom?.quantity ?? 1)).toLocaleString('en-US')
                            )}
                          </>
                        </Typography>
                        {displayPricing && (
                          <>
                            {'unitCost' in data && 'unitOfMeasure' in data && (
                              <Typography sx={descriptionSx}>
                                Unit Price: {numeral(data.unitCost.subTotal).format('$0,0.0000')} / {data.unitOfMeasure?.identifier ?? ''}
                              </Typography>
                            )}
                            {'listPrice' in data && 'soldAs' in data && (
                              <Typography sx={descriptionSx}>
                                Unit Price: {numeral(data.listPrice).format('$0,0.0000')} / {data.soldAs.identifier}
                              </Typography>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                </Grid>
                {!ordered && !readonly && (
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                      <Box sx={{ width: '150px' }}>
                        <TextField
                          fullWidth={true}
                          id="outlined-basic"
                          size="small"
                          type="number"
                          error={errorMessage ? true : false}
                          inputProps={{
                            sx: {
                              fontSize: '16px',
                              lineHeight: '16px',
                              fontWeight: 600,
                            },
                            inputMode: 'numeric',
                            min: 1,
                            max: maxAddToCartQuantity,
                            pattern: '[0-9]*',
                          }}
                          variant="standard"
                          name="quantity"
                          label="Quantity"
                          value={quantity}
                          onKeyDown={(evt) => {
                            const key: string = evt.key
                            if (key.length <= 1) {
                              const regex = /[0-9]|\./
                              if (!regex.test(key)) evt.preventDefault()
                            }
                          }}
                          onChange={handleChange}
                          disabled={editDisabled}
                        />
                      </Box>
                      {!editDisabled && (
                        <Button
                          label={t('cart.product_card.buttons.remove')}
                          variant="text"
                          size="small"
                          sx={{
                            color: theme.palette.common.blue,
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '120%',
                          }}
                          onClick={remove}
                        />
                      )}
                    </Box>
                  </Grid>
                )}
                {errorMessage && (
                  <Grid container xs={12}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: theme.palette.error.main,
                        textTransform: 'none',
                        lineHeight: 1.4,
                        display: 'block',
                        ml: 1,
                      }}
                    >
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
                {size === 'default' && (
                  <Stack sx={{ mt: readonly ? 0 : 1, ml: 1 }}>
                    <Typography sx={descriptionSx}>
                      {t('product.sold_as')}:{' '}
                      {uom ? `${uom.name} (${uom.quantity.toLocaleString('en-US')} ${uom.quantity > 1 ? 'pcs' : 'pc'})` : 'TBD'}
                    </Typography>
                    <Typography
                      sx={{
                        ...descriptionSx,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {data.quantity * (uom?.quantity ?? 1) > 1 ? t('product.total_eaches') : t('product.total_each')}:{' '}
                      <>
                        {cartItemIsLoading ? (
                          <Skeleton variant="rectangular" width="50px" height="19.2px" sx={{ ml: '8px' }} />
                        ) : (
                          (data.quantity * (uom?.quantity ?? 1)).toLocaleString('en-US')
                        )}
                      </>
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {displayPricing && size === 'default' && (
        <>
          <Grid item xs={2}>
            {cartItemIsLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Skeleton variant="rectangular" width="50px" height="19.2px" />
              </Box>
            ) : (
              <>
                {'unitCost' in data && <Typography sx={pricingSx}>{numeral(data.unitCost.subTotal).format('$0,0.0000')}</Typography>}
                {'listPrice' in data && <Typography sx={pricingSx}>{numeral(data.listPrice).format('$0,0.0000')}</Typography>}
              </>
            )}
          </Grid>
          <Grid item xs={2}>
            {cartItemIsLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Skeleton variant="rectangular" width="50px" height="19.2px" />
              </Box>
            ) : (
              <>
                {'totalCost' in data && <Typography sx={pricingSx}>{numeral(data.totalCost.subTotal).format('$0,0.00')}</Typography>}
                {'listPrice' in data && <Typography sx={pricingSx}>{numeral(data.quantity * data.listPrice).format('$0,0.00')}</Typography>}
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
})

export { B2BCartItem }
