type CountryOverrides = 'REMOVE_POSTAL_CODE' | 'REMOVE_STATE_PROVINCE'
export const Countries: {
  iso2: string
  iso3: string
  displayName: string
  experianValidate: string | null
  experianAutocomplete: string | null
  overrides?: CountryOverrides[]
  postalCodeOptional?: boolean
  stateOrProvinceRequired?: boolean
}[] = [
  {
    iso2: 'AS',
    iso3: 'ASM',
    displayName: 'American Samoa',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'AD',
    iso3: 'AND',
    displayName: 'Andorra',
    experianAutocomplete: 'ad-address-ed',
    experianValidate: 'ad-address-ed',
  },
  {
    iso2: 'AI',
    iso3: 'AIA',
    displayName: 'Anguilla',
    experianAutocomplete: 'ai-address-ed',
    experianValidate: 'ai-address-ed',
  },
  {
    iso2: 'AQ',
    iso3: 'ATA',
    displayName: 'Antarctica',
    experianAutocomplete: 'aq-address-ed',
    experianValidate: 'aq-address-ed',
  },
  {
    iso2: 'AG',
    iso3: 'ATG',
    displayName: 'Antigua and Barbuda',
    experianAutocomplete: 'ag-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'AR',
    iso3: 'ARG',
    displayName: 'Argentina',
    experianAutocomplete: 'ar-address-ed',
    experianValidate: 'ar-address-ed',
  },
  {
    iso2: 'AM',
    iso3: 'ARM',
    displayName: 'Armenia',
    experianAutocomplete: 'am-address-ed',
    experianValidate: 'am-address-ed',
  },
  {
    iso2: 'AW',
    iso3: 'ABW',
    displayName: 'Aruba',
    experianAutocomplete: 'aw-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'AU',
    iso3: 'AUS',
    displayName: 'Australia',
    experianAutocomplete: 'au-address',
    experianValidate: 'au-address',
  },
  {
    iso2: 'AT',
    iso3: 'AUT',
    displayName: 'Austria',
    experianAutocomplete: 'at-address-ed',
    experianValidate: 'at-address-ed',
  },
  {
    iso2: 'AZ',
    iso3: 'AZE',
    displayName: 'Azerbaijan',
    experianAutocomplete: 'az-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BS',
    iso3: 'BHS',
    displayName: 'Bahamas',
    experianAutocomplete: 'bs-address-ed',
    experianValidate: 'bs-address-ed',
  },
  {
    iso2: 'BH',
    iso3: 'BHR',
    displayName: 'Bahrain',
    experianAutocomplete: 'bh-address-ed',
    experianValidate: 'bh-address-ed',
  },
  {
    iso2: 'BD',
    iso3: 'BGD',
    displayName: 'Bangladesh',
    experianAutocomplete: 'bd-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BB',
    iso3: 'BRB',
    displayName: 'Barbados',
    experianAutocomplete: 'bb-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BE',
    iso3: 'BEL',
    displayName: 'Belgium',
    experianAutocomplete: 'be-address',
    experianValidate: 'be-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'BZ',
    iso3: 'BLZ',
    displayName: 'Belize',
    experianAutocomplete: 'bz-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BJ',
    iso3: 'BEN',
    displayName: 'Benin',
    experianAutocomplete: 'bj-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BM',
    iso3: 'BMU',
    displayName: 'Bermuda',
    experianAutocomplete: 'bm-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BT',
    iso3: 'BTN',
    displayName: 'Bhutan',
    experianAutocomplete: 'bt-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BO',
    iso3: 'BOL',
    displayName: 'Bolivia',
    experianAutocomplete: 'bo-address-ed',
    experianValidate: 'bo-address-ed',
  },
  {
    iso2: 'BQ',
    iso3: 'BES',
    displayName: 'Bonaire, Sint Eustatius and Saba',
    experianAutocomplete: 'bq-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BW',
    iso3: 'BWA',
    displayName: 'Botswana',
    experianAutocomplete: 'bw-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BR',
    iso3: 'BRA',
    displayName: 'Brazil',
    experianAutocomplete: 'br-address-ed',
    experianValidate: 'br-address-ed',
  },
  {
    iso2: 'IO',
    iso3: 'IOT',
    displayName: 'British Indian Ocean Territory',
    experianAutocomplete: 'io-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'BN',
    iso3: 'BRN',
    displayName: 'Brunei Darussalam',
    experianAutocomplete: 'bn-address-ed',
    experianValidate: 'bn-address-ed',
  },
  {
    iso2: 'BG',
    iso3: 'BGR',
    displayName: 'Bulgaria',
    experianAutocomplete: 'bg-address-ed',
    experianValidate: 'bg-address-ed',
  },
  {
    iso2: 'BF',
    iso3: 'BFA',
    displayName: 'Burkina Faso',
    experianAutocomplete: 'bf-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'KH',
    iso3: 'KHM',
    displayName: 'Cambodia',
    experianAutocomplete: 'kh-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'CM',
    iso3: 'CMR',
    displayName: 'Cameroon',
    experianAutocomplete: 'cm-address-ed',
    experianValidate: 'cm-address-ed',
  },
  {
    iso2: 'CA',
    iso3: 'CAN',
    displayName: 'Canada',
    experianAutocomplete: 'ca-address',
    experianValidate: 'ca-address',
    stateOrProvinceRequired: true,
  },
  {
    iso2: 'CV',
    iso3: 'CPV',
    displayName: 'Cape Verde',
    experianAutocomplete: 'cv-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'KY',
    iso3: 'CYM',
    displayName: 'Cayman Islands',
    experianAutocomplete: 'ky-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'CF',
    iso3: 'CAF',
    displayName: 'Central African Republic',
    experianAutocomplete: 'cf-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TD',
    iso3: 'TCD',
    displayName: 'Chad',
    experianAutocomplete: 'td-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'CL',
    iso3: 'CHL',
    displayName: 'Chile',
    experianAutocomplete: 'cl-address-ed',
    experianValidate: 'cl-address-ed',
  },
  {
    iso2: 'CN',
    iso3: 'CHN',
    displayName: 'China',
    experianAutocomplete: 'cn-address-ed',
    experianValidate: 'cn-address-ed',
  },
  {
    iso2: 'CX',
    iso3: 'CXR',
    displayName: 'Christmas Island',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'CC',
    iso3: 'CCK',
    displayName: 'Cocos (Keeling) Islands',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'CO',
    iso3: 'COL',
    displayName: 'Colombia',
    experianAutocomplete: 'co-address-ed',
    experianValidate: 'co-address-ed',
  },
  {
    iso2: 'KM',
    iso3: 'COM',
    displayName: 'Comoros',
    experianAutocomplete: 'km-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'CK',
    iso3: 'COK',
    displayName: 'Cook Islands',
    experianAutocomplete: 'ck-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'CR',
    iso3: 'CRI',
    displayName: 'Costa Rica',
    experianAutocomplete: 'cr-address-ed',
    experianValidate: 'cr-address-ed',
  },
  {
    iso2: 'HR',
    iso3: 'HRV',
    displayName: 'Croatia',
    experianAutocomplete: 'hr-address-ed',
    experianValidate: 'hr-address-ed',
  },
  {
    iso2: 'CY',
    iso3: 'CYP',
    displayName: 'Cyprus',
    experianAutocomplete: 'cy-address-ed',
    experianValidate: 'cy-address-ed',
  },
  {
    iso2: 'CZ',
    iso3: 'CZE',
    displayName: 'Czech Republic',
    experianAutocomplete: 'cz-address-ed',
    experianValidate: 'cz-address-ed',
  },
  {
    iso2: 'DK',
    iso3: 'DNK',
    displayName: 'Denmark',
    experianAutocomplete: 'dk-address',
    experianValidate: 'dk-address',
  },
  {
    iso2: 'DJ',
    iso3: 'DJI',
    displayName: 'Djibouti',
    experianAutocomplete: 'dj-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'DM',
    iso3: 'DMA',
    displayName: 'Dominica',
    experianAutocomplete: 'dm-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'EC',
    iso3: 'ECU',
    displayName: 'Ecuador',
    experianAutocomplete: 'ec-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'EG',
    iso3: 'EGY',
    displayName: 'Egypt',
    experianAutocomplete: 'eg-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'SV',
    iso3: 'SLV',
    displayName: 'El Salvador',
    experianAutocomplete: 'sv-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GQ',
    iso3: 'GNQ',
    displayName: 'Equatorial Guinea',
    experianAutocomplete: 'gq-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'ER',
    iso3: 'ERI',
    displayName: 'Eritrea',
    experianAutocomplete: 'er-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'EE',
    iso3: 'EST',
    displayName: 'Estonia',
    experianAutocomplete: 'ee-address-ed',
    experianValidate: 'ee-address-ed',
  },
  {
    iso2: 'SZ',
    iso3: 'SWZ',
    displayName: 'Eswatini',
    experianAutocomplete: 'sz-address-ed',
    experianValidate: 'sz-address-ed',
  },
  {
    iso2: 'ET',
    iso3: 'ETH',
    displayName: 'Ethiopia',
    experianAutocomplete: 'et-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'FK',
    iso3: 'FLK',
    displayName: 'Falkland Islands (Malvinas)',
    experianAutocomplete: 'fk-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'FO',
    iso3: 'FRO',
    displayName: 'Faroe Islands',
    experianAutocomplete: 'fo-address-ed',
    experianValidate: 'fo-address-ed',
  },
  {
    iso2: 'FJ',
    iso3: 'FJI',
    displayName: 'Fiji',
    experianAutocomplete: 'fj-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'FI',
    iso3: 'FIN',
    displayName: 'Finland',
    experianAutocomplete: 'fi-address',
    experianValidate: 'fi-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'FR',
    iso3: 'FRA',
    displayName: 'France',
    experianAutocomplete: 'fr-address-streetlevel',
    experianValidate: 'fr-address-streetlevel',
  },
  {
    iso2: 'GF',
    iso3: 'GUF',
    displayName: 'French Guiana',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'PF',
    iso3: 'PYF',
    displayName: 'French Polynesia',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'GA',
    iso3: 'GAB',
    displayName: 'Gabon',
    experianAutocomplete: 'ga-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GM',
    iso3: 'GMB',
    displayName: 'Gambia',
    experianAutocomplete: 'gm-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GE',
    iso3: 'GEO',
    displayName: 'Georgia',
    experianAutocomplete: 'ge-address-ed',
    experianValidate: 'ge-address-ed',
  },
  {
    iso2: 'DE',
    iso3: 'DEU',
    displayName: 'Germany',
    experianAutocomplete: 'de-address',
    experianValidate: 'de-address',
  },
  {
    iso2: 'GH',
    iso3: 'GHA',
    displayName: 'Ghana',
    experianAutocomplete: 'gh-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GI',
    iso3: 'GIB',
    displayName: 'Gibraltar',
    experianAutocomplete: 'gi-address-ed',
    experianValidate: 'gi-address-ed',
  },
  {
    iso2: 'GR',
    iso3: 'GRC',
    displayName: 'Greece',
    experianAutocomplete: 'gr-address-ed',
    experianValidate: 'gr-address-ed',
  },
  {
    iso2: 'GL',
    iso3: 'GRL',
    displayName: 'Greenland',
    experianAutocomplete: 'gl-address-ed',
    experianValidate: 'gl-address-ed',
  },
  {
    iso2: 'GD',
    iso3: 'GRD',
    displayName: 'Grenada',
    experianAutocomplete: 'gd-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GP',
    iso3: 'GLP',
    displayName: 'Guadeloupe',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'GU',
    iso3: 'GUM',
    displayName: 'Guam',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'GT',
    iso3: 'GTM',
    displayName: 'Guatemala',
    experianAutocomplete: 'gt-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GN',
    iso3: 'GIN',
    displayName: 'Guinea',
    experianAutocomplete: 'gn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GW',
    iso3: 'GNB',
    displayName: 'Guinea-Bissau',
    experianAutocomplete: 'gw-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'GY',
    iso3: 'GUY',
    displayName: 'Guyana',
    experianAutocomplete: 'gy-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'HT',
    iso3: 'HTI',
    displayName: 'Haiti',
    experianAutocomplete: 'ht-address-ed',
    experianValidate: 'ht-address-ed',
  },
  {
    iso2: 'VA',
    iso3: 'VAT',
    displayName: 'Holy See',
    experianAutocomplete: 'va-address-ed',
    experianValidate: 'va-address-ed',
  },
  {
    iso2: 'HN',
    iso3: 'HND',
    displayName: 'Honduras',
    experianAutocomplete: 'hn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'HK',
    iso3: 'HKG',
    displayName: 'Hong Kong',
    experianAutocomplete: 'hk-address-ed',
    experianValidate: 'hk-address-ed',
    postalCodeOptional: true,
  },
  {
    iso2: 'HU',
    iso3: 'HUN',
    displayName: 'Hungary',
    experianAutocomplete: 'hu-address-ed',
    experianValidate: 'hu-address-ed',
  },
  {
    iso2: 'IS',
    iso3: 'ISL',
    displayName: 'Iceland',
    experianAutocomplete: 'is-address-ed',
    experianValidate: 'is-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'IN',
    iso3: 'IND',
    displayName: 'India',
    experianAutocomplete: 'in-address-ed',
    experianValidate: 'in-address-ed',
  },
  {
    iso2: 'ID',
    iso3: 'IDN',
    displayName: 'Indonesia',
    experianAutocomplete: 'id-address-ed',
    experianValidate: 'id-address-ed',
  },
  {
    iso2: 'IE',
    iso3: 'IRL',
    displayName: 'Ireland',
    experianAutocomplete: 'ie-address-ecad',
    experianValidate: null,
  },
  {
    iso2: 'IL',
    iso3: 'ISR',
    displayName: 'Israel',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'IT',
    iso3: 'ITA',
    displayName: 'Italy',
    experianAutocomplete: 'it-address-ed',
    experianValidate: 'it-address-ed',
  },
  {
    iso2: 'JM',
    iso3: 'JAM',
    displayName: 'Jamaica',
    experianAutocomplete: 'jm-address-ed',
    experianValidate: null,
    postalCodeOptional: true,
  },
  {
    iso2: 'JP',
    iso3: 'JPN',
    displayName: 'Japan',
    experianAutocomplete: 'jp-address-ed',
    experianValidate: 'jp-address-ed',
  },
  {
    iso2: 'KZ',
    iso3: 'KAZ',
    displayName: 'Kazakhstan',
    experianAutocomplete: 'kz-address-ed',
    experianValidate: 'kz-address-ed',
  },
  {
    iso2: 'KE',
    iso3: 'KEN',
    displayName: 'Kenya',
    experianAutocomplete: 'ke-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'KI',
    iso3: 'KIR',
    displayName: 'Kiribati',
    experianAutocomplete: 'ki-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'KR',
    iso3: 'KOR',
    displayName: 'Korea, Republic of',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'KW',
    iso3: 'KWT',
    displayName: 'Kuwait',
    experianAutocomplete: 'kw-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'KG',
    iso3: 'KGZ',
    displayName: 'Kyrgyzstan',
    experianAutocomplete: 'kg-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'LA',
    iso3: 'LAO',
    displayName: "Lao People's Democratic Republic",
    experianAutocomplete: 'la-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'LV',
    iso3: 'LVA',
    displayName: 'Latvia',
    experianAutocomplete: 'lv-address-ed',
    experianValidate: 'lv-address-ed',
  },
  {
    iso2: 'LB',
    iso3: 'LBN',
    displayName: 'Lebanon',
    experianAutocomplete: 'lb-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'LS',
    iso3: 'LSO',
    displayName: 'Lesotho',
    experianAutocomplete: 'ls-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'LI',
    iso3: 'LIE',
    displayName: 'Liechtenstein',
    experianAutocomplete: 'li-address-ed',
    experianValidate: 'li-address-ed',
  },
  {
    iso2: 'LT',
    iso3: 'LTU',
    displayName: 'Lithuania',
    experianAutocomplete: 'lt-address-ed',
    experianValidate: 'lt-address-ed',
  },
  {
    iso2: 'LU',
    iso3: 'LUX',
    displayName: 'Luxembourg',
    experianAutocomplete: 'lu-address',
    experianValidate: 'lu-address',
  },
  {
    iso2: 'MO',
    iso3: 'MAC',
    displayName: 'Macao',
    experianAutocomplete: 'mo-address-ed',
    experianValidate: 'mo-address-ed',
  },
  {
    iso2: 'MG',
    iso3: 'MDG',
    displayName: 'Madagascar',
    experianAutocomplete: 'mg-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'MW',
    iso3: 'MWI',
    displayName: 'Malawi',
    experianAutocomplete: 'mw-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'MY',
    iso3: 'MYS',
    displayName: 'Malaysia',
    experianAutocomplete: 'my-address-ed',
    experianValidate: 'my-address-ed',
  },
  {
    iso2: 'MV',
    iso3: 'MDV',
    displayName: 'Maldives',
    experianAutocomplete: 'mv-address-ed',
    experianValidate: 'mv-address-ed',
  },
  {
    iso2: 'ML',
    iso3: 'MLI',
    displayName: 'Mali',
    experianAutocomplete: 'ml-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'MT',
    iso3: 'MLT',
    displayName: 'Malta',
    experianAutocomplete: 'mt-address-ed',
    experianValidate: 'mt-address-ed',
  },
  {
    iso2: 'MH',
    iso3: 'MHL',
    displayName: 'Marshall Islands',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'MQ',
    iso3: 'MTQ',
    displayName: 'Martinique',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'MR',
    iso3: 'MRT',
    displayName: 'Mauritania',
    experianAutocomplete: 'mr-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'MU',
    iso3: 'MUS',
    displayName: 'Mauritius',
    experianAutocomplete: 'mu-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'YT',
    iso3: 'MYT',
    displayName: 'Mayotte',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'MX',
    iso3: 'MEX',
    displayName: 'Mexico',
    experianAutocomplete: 'mx-address-ed',
    experianValidate: 'mx-address-ed',
  },
  {
    iso2: 'MC',
    iso3: 'MCO',
    displayName: 'Monaco',
    experianAutocomplete: 'mc-address-ed',
    experianValidate: 'mc-address-ed',
  },
  {
    iso2: 'MN',
    iso3: 'MNG',
    displayName: 'Mongolia',
    experianAutocomplete: 'mn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'MS',
    iso3: 'MSR',
    displayName: 'Montserrat',
    experianAutocomplete: 'ms-address-ed',
    experianValidate: 'ms-address-ed',
  },
  {
    iso2: 'MA',
    iso3: 'MAR',
    displayName: 'Morocco',
    experianAutocomplete: 'ma-address-ed',
    experianValidate: 'ma-address-ed',
  },
  {
    iso2: 'MZ',
    iso3: 'MOZ',
    displayName: 'Mozambique',
    experianAutocomplete: 'mz-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NA',
    iso3: 'NAM',
    displayName: 'Namibia',
    experianAutocomplete: 'na-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NR',
    iso3: 'NRU',
    displayName: 'Nauru',
    experianAutocomplete: 'nr-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NP',
    iso3: 'NPL',
    displayName: 'Nepal',
    experianAutocomplete: 'np-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NL',
    iso3: 'NLD',
    displayName: 'Netherlands',
    experianAutocomplete: 'nl-address',
    experianValidate: 'nl-address',
  },
  {
    iso2: 'NC',
    iso3: 'NCL',
    displayName: 'New Caledonia',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'NZ',
    iso3: 'NZL',
    displayName: 'New Zealand',
    experianAutocomplete: 'nz-address',
    experianValidate: 'nz-address',
  },
  {
    iso2: 'NI',
    iso3: 'NIC',
    displayName: 'Nicaragua',
    experianAutocomplete: 'ni-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NE',
    iso3: 'NER',
    displayName: 'Niger',
    experianAutocomplete: 'ne-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NU',
    iso3: 'NIU',
    displayName: 'Niue',
    experianAutocomplete: 'nu-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'NF',
    iso3: 'NFK',
    displayName: 'Norfolk Island',
    experianAutocomplete: 'nf-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'MP',
    iso3: 'MNP',
    displayName: 'Northern Mariana Islands',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'NO',
    iso3: 'NOR',
    displayName: 'Norway',
    experianAutocomplete: 'no-address',
    experianValidate: 'no-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'OM',
    iso3: 'OMN',
    displayName: 'Oman',
    experianAutocomplete: 'om-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'PK',
    iso3: 'PAK',
    displayName: 'Pakistan',
    experianAutocomplete: 'pk-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'PW',
    iso3: 'PLW',
    displayName: 'Palau',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'PS',
    iso3: 'PSE',
    displayName: 'Palestine, State of',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'PA',
    iso3: 'PAN',
    displayName: 'Panama',
    experianAutocomplete: 'pa-address-ed',
    experianValidate: 'pa-address-ed',
  },
  {
    iso2: 'PG',
    iso3: 'PNG',
    displayName: 'Papua New Guinea',
    experianAutocomplete: 'pg-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'PE',
    iso3: 'PER',
    displayName: 'Peru',
    experianAutocomplete: 'pe-address-ed',
    experianValidate: 'pe-address-ed',
  },
  {
    iso2: 'PH',
    iso3: 'PHL',
    displayName: 'Philippines',
    experianAutocomplete: 'ph-address-ed',
    experianValidate: 'ph-address-ed',
  },
  {
    iso2: 'PN',
    iso3: 'PCN',
    displayName: 'Pitcairn',
    experianAutocomplete: 'pn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'PL',
    iso3: 'POL',
    displayName: 'Poland',
    experianAutocomplete: 'pl-address-ed',
    experianValidate: 'pl-address-ed',
  },
  {
    iso2: 'PT',
    iso3: 'PRT',
    displayName: 'Portugal',
    experianAutocomplete: 'pt-address-ed',
    experianValidate: 'pt-address-ed',
  },
  {
    iso2: 'QA',
    iso3: 'QAT',
    displayName: 'Qatar',
    experianAutocomplete: 'qa-address-ed',
    experianValidate: 'qa-address-ed',
  },
  {
    iso2: 'RO',
    iso3: 'ROU',
    displayName: 'Romania',
    experianAutocomplete: 'ro-address-ed',
    experianValidate: 'ro-address-ed',
  },
  {
    iso2: 'RW',
    iso3: 'RWA',
    displayName: 'Rwanda',
    experianAutocomplete: 'rw-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'RE',
    iso3: 'REU',
    displayName: 'Réunion',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'SH',
    iso3: 'SHN',
    displayName: 'Saint Helena, Ascension and Tristan da Cunha',
    experianAutocomplete: 'sh-address-ed',
    experianValidate: 'sh-address-ed',
  },
  {
    iso2: 'KN',
    iso3: 'KNA',
    displayName: 'Saint Kitts and Nevis',
    experianAutocomplete: 'kn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'LC',
    iso3: 'LCA',
    displayName: 'Saint Lucia',
    experianAutocomplete: 'lc-address-ed',
    experianValidate: 'lc-address-ed',
  },
  {
    iso2: 'MF',
    iso3: 'MAF',
    displayName: 'Saint Martin, (French part)',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'PM',
    iso3: 'SPM',
    displayName: 'Saint Pierre and Miquelon',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'VC',
    iso3: 'VCT',
    displayName: 'Saint Vincent and the Grenadines',
    experianAutocomplete: 'vc-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'WS',
    iso3: 'WSM',
    displayName: 'Samoa',
    experianAutocomplete: 'ws-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'SM',
    iso3: 'SMR',
    displayName: 'San Marino',
    experianAutocomplete: 'sm-address-ed',
    experianValidate: 'sm-address-ed',
  },
  {
    iso2: 'ST',
    iso3: 'STP',
    displayName: 'Sao Tome and Principe',
    experianAutocomplete: 'st-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'SA',
    iso3: 'SAU',
    displayName: 'Saudi Arabia',
    experianAutocomplete: 'sa-address-ed',
    experianValidate: 'sa-address-ed',
  },
  {
    iso2: 'SN',
    iso3: 'SEN',
    displayName: 'Senegal',
    experianAutocomplete: 'sn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'SC',
    iso3: 'SYC',
    displayName: 'Seychelles',
    experianAutocomplete: 'sc-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'SG',
    iso3: 'SGP',
    displayName: 'Singapore',
    experianAutocomplete: 'sg-address',
    experianValidate: 'sg-address',
  },
  {
    iso2: 'SX',
    iso3: 'SXM',
    displayName: 'Sint Maarten, (Dutch part)',
    experianAutocomplete: 'sx-address-ed',
    experianValidate: 'sx-address-ed',
  },
  {
    iso2: 'SK',
    iso3: 'SVK',
    displayName: 'Slovakia',
    experianAutocomplete: 'sk-address-ed',
    experianValidate: 'sk-address-ed',
  },
  {
    iso2: 'SI',
    iso3: 'SVN',
    displayName: 'Slovenia',
    experianAutocomplete: 'si-address-ed',
    experianValidate: 'si-address-ed',
  },
  {
    iso2: 'SB',
    iso3: 'SLB',
    displayName: 'Solomon Islands',
    experianAutocomplete: 'sb-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'ZA',
    iso3: 'ZAF',
    displayName: 'South Africa',
    experianAutocomplete: 'za-address-ed',
    experianValidate: 'za-address-ed',
  },
  {
    iso2: 'GS',
    iso3: 'SGS',
    displayName: 'South Georgia and the South Sandwich Islands',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'SS',
    iso3: 'SSD',
    displayName: 'South Sudan',
    experianAutocomplete: 'ss-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'ES',
    iso3: 'ESP',
    displayName: 'Spain',
    experianAutocomplete: 'es-address',
    experianValidate: 'es-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'SR',
    iso3: 'SUR',
    displayName: 'Suriname',
    experianAutocomplete: 'sr-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'SJ',
    iso3: 'SJM',
    displayName: 'Svalbard and Jan Mayen',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'SE',
    iso3: 'SWE',
    displayName: 'Sweden',
    experianAutocomplete: 'se-address',
    experianValidate: 'se-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'CH',
    iso3: 'CHE',
    displayName: 'Switzerland',
    experianAutocomplete: 'ch-address',
    experianValidate: 'ch-address-ed',
    // overrides: ['REMOVE_POSTAL_CODE', 'REMOVE_STATE_PROVINCE'],
  },
  {
    iso2: 'TW',
    iso3: 'TWN',
    displayName: 'Taiwan',
    experianAutocomplete: 'tw-address-ed',
    experianValidate: 'tw-address-ed',
  },
  {
    iso2: 'TJ',
    iso3: 'TJK',
    displayName: 'Tajikistan',
    experianAutocomplete: 'tj-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TZ',
    iso3: 'TZA',
    displayName: 'Tanzania, United Republic of',
    experianAutocomplete: 'tz-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TH',
    iso3: 'THA',
    displayName: 'Thailand',
    experianAutocomplete: 'th-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TL',
    iso3: 'TLS',
    displayName: 'Timor-Leste',
    experianAutocomplete: 'tl-address-ed',
    experianValidate: 'tl-address-ed',
  },
  {
    iso2: 'TK',
    iso3: 'TKL',
    displayName: 'Tokelau',
    experianAutocomplete: 'tk-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TO',
    iso3: 'TON',
    displayName: 'Tonga',
    experianAutocomplete: 'to-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TT',
    iso3: 'TTO',
    displayName: 'Trinidad and Tobago',
    experianAutocomplete: 'tt-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TN',
    iso3: 'TUN',
    displayName: 'Tunisia',
    experianAutocomplete: 'tn-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TR',
    iso3: 'TUR',
    displayName: 'Turkey',
    experianAutocomplete: 'tr-address-ed',
    experianValidate: 'tr-address-ed',
  },
  {
    iso2: 'TM',
    iso3: 'TKM',
    displayName: 'Turkmenistan',
    experianAutocomplete: 'tm-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TC',
    iso3: 'TCA',
    displayName: 'Turks and Caicos Islands',
    experianAutocomplete: 'tc-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'TV',
    iso3: 'TUV',
    displayName: 'Tuvalu',
    experianAutocomplete: 'tv-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'UG',
    iso3: 'UGA',
    displayName: 'Uganda',
    experianAutocomplete: 'ug-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'UA',
    iso3: 'UKR',
    displayName: 'Ukraine',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'AE',
    iso3: 'ARE',
    displayName: 'United Arab Emirates',
    experianAutocomplete: 'ae-address-ed',
    experianValidate: 'ae-address-ed',
    postalCodeOptional: true,
  },
  {
    iso2: 'GB',
    iso3: 'GBR',
    displayName: 'United Kingdom',
    experianAutocomplete: 'gb-address',
    experianValidate: 'gb-address',
  },
  {
    iso2: 'US',
    iso3: 'USA',
    displayName: 'United States',
    experianAutocomplete: 'us-address',
    experianValidate: 'us-address',
    stateOrProvinceRequired: true,
  },
  {
    iso2: 'UY',
    iso3: 'URY',
    displayName: 'Uruguay',
    experianAutocomplete: 'uy-address-ed',
    experianValidate: 'uy-address-ed',
  },
  {
    iso2: 'UZ',
    iso3: 'UZB',
    displayName: 'Uzbekistan',
    experianAutocomplete: 'uz-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'VU',
    iso3: 'VUT',
    displayName: 'Vanuatu',
    experianAutocomplete: 'vu-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'VE',
    iso3: 'VEN',
    displayName: 'Venezuela, Bolivarian Republic of',
    experianAutocomplete: 've-address-ed',
    experianValidate: 've-address-ed',
  },
  {
    iso2: 'VN',
    iso3: 'VNM',
    displayName: 'Viet Nam',
    experianAutocomplete: 'vn-address-ed',
    experianValidate: 'vn-address-ed',
  },
  {
    iso2: 'VG',
    iso3: 'VGB',
    displayName: 'Virgin Islands, British',
    experianAutocomplete: 'vg-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'VI',
    iso3: 'VIR',
    displayName: 'Virgin Islands, U.S.',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'WF',
    iso3: 'WLF',
    displayName: 'Wallis and Futuna',
    experianAutocomplete: null,
    experianValidate: null,
  },
  {
    iso2: 'EH',
    iso3: 'ESH',
    displayName: 'Western Sahara',
    experianAutocomplete: 'eh-address-ed',
    experianValidate: null,
  },
  {
    iso2: 'ZM',
    iso3: 'ZMB',
    displayName: 'Zambia',
    experianAutocomplete: 'zm-address-ed',
    experianValidate: 'zm-address-ed',
  },
]
