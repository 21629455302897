import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { OrderItem } from '../../types/order'
import { IProductSimple } from '../../types/product'
import { Feature, Kpi, CustomerDetails } from '../../types/dashboard'

export interface DashboardState {
  customerDetails?: CustomerDetails
  recommendedProducts: IProductSimple[]
  recentOrders: OrderItem[]
  newFeatures: Feature[]
  articles: Feature[]
  kpi?: Kpi
}

const initialState: DashboardState = {
  recommendedProducts: [],
  recentOrders: [],
  newFeatures: [],
  articles: [],
}

export const dashboardSlice = createSlice({
  name: 'dashboardStore',
  initialState,
  reducers: {
    setCustomerDetails: (state, { payload }: PayloadAction<CustomerDetails>) => {
      state.customerDetails = payload
    },
    setRecommendedProducts: (state, { payload }: PayloadAction<IProductSimple[]>) => {
      state.recommendedProducts = payload
    },
    setRecentOrders: (state, { payload }: PayloadAction<OrderItem[]>) => {
      state.recentOrders = payload
    },
    setNewFeatures: (state, { payload }: PayloadAction<Feature[]>) => {
      state.newFeatures = payload
    },
    setArticles: (state, { payload }: PayloadAction<Feature[]>) => {
      state.articles = payload
    },
    setKpi: (state, { payload }: PayloadAction<Kpi>) => {
      state.kpi = payload
    },
    reset: (state) => {
      state.recommendedProducts = []
      state.recentOrders = []
      state.newFeatures = []
      state.articles = []
      state.kpi = undefined
      state.customerDetails = undefined
    },
  },
})

export const { setCustomerDetails, setRecommendedProducts, setRecentOrders, setNewFeatures, setArticles, setKpi, reset } =
  dashboardSlice.actions
export const { reducer } = dashboardSlice
