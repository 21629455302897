// material-ui
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'

import ProfileSection from './ProfileSection'
import SearchSection from './SearchSection'
import CartSection from './CartSection'
import NavSection from './NavSection'
import MobileMenu from './MobileMenu'

import { getLowerEnv, isProduction, isQorpak } from '../../../utils/common'
import { logoutRequest } from '../../../authConfig'
import { resetState } from '../../../stores/rootReducer'
import { clearCart } from '../../../stores/b2b/CartStore/cartSlice'
import { storeUserCart } from '../../../stores/b2b/CartStore/actions'
import { setRole, setLogoutInProgress } from '../../../stores/AuthStore/authSlice'
import { getPlatform, trackClick } from '../../../utils/segment'
import { deleteCookie } from '../../../utils/cookie'
import LogoSection from '../LogoSection'
// import NotificationSection from './NotificationSection'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { selectRole, selectUser } from '../../../stores/AuthStore/selectors'
import { UserRole } from '../../../types/user'
import { selectIsCrossBrandUser } from '../../../stores/UserStore/selectors'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

type Props = {
  shouldDisplayProducts: boolean
}

const Header = ({ shouldDisplayProducts = false }: Props) => {
  const user = useAppSelector(selectUser)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [catalogHref, setCatalogHref] = useState<string>('')
  const theme = useTheme()
  const isCheckout = window.location.href?.includes('checkout')
  const isB2BCheckoutEnabled = user?.userRoles?.includes('Customer B2B Checkout')
  const isMobile = useMediaQuery(theme.breakpoints.down(800)) // 800 is the min width where all elements are still stackable horizontally
  const userRole = useAppSelector(selectRole)
  const { instance } = useMsal()
  const dispatch = useAppDispatch()
  const iconColor = isQorpak() ? theme.palette.common.qorpakSecondary : theme.palette.common.primaryBlue
  const isCrossBrandUser = useAppSelector(selectIsCrossBrandUser)

  useEffect(() => {
    if (isQorpak()) {
      setCatalogHref(`https://${isProduction() ? '' : getLowerEnv() + '.'}shop.qorpak.com/products/categories/index`)
    } else {
      setCatalogHref('/products/list')
    }
  }, [])

  const handleLogout = async () => {
    // Segment tracking
    trackClick('Log Out', {
      label: 'Log Out',
      platform: getPlatform(userRole === UserRole.EMPLOYEE),
      category: 'Dashboard',
    })
    dispatch(storeUserCart())
    dispatch(setRole(null))
    dispatch(setLogoutInProgress(true))
    window.localStorage.removeItem('token')
    dispatch(clearCart())
    if (userRole === UserRole.EMPLOYEE) {
      window.location.href = 'https://berlin.okta.com'
    } else {
      deleteCookie(`x-ms-cpim-sso:${process.env.REACT_APP_B2C_TENANT_DOMAIN ?? 'bpkgomnitest.onmicrosoft.com'}_0`)
      dispatch(resetState())
      instance.logoutRedirect(logoutRequest)
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1500px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          // py: '8px',
          height: '84px',
          px: '16px',
        }}
      >
        {/* logo */}
        <Box sx={{ flexShrink: 0 }}>
          <LogoSection />
        </Box>

        {/* interactive components */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          {!isCrossBrandUser && (
            <>
              {/* search */}
              {shouldDisplayProducts && (
                <Box sx={{ width: { xs: '100%', md: '200px' }, flexGrow: { xs: 1, md: 0 } }}>
                  <SearchSection />
                </Box>
              )}

              {/* links */}
              {!isMobile && (
                <NavSection shouldDisplayCatalog={(shouldDisplayProducts && !isQorpak()) || isQorpak()} catalogHref={catalogHref} />
              )}

              {/* checkout */}
              {isB2BCheckoutEnabled && !isCheckout && <CartSection />}
            </>
          )}

          {/* profile */}
          {!isMobile && (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <ProfileSection handleLogout={handleLogout} />
            </Box>
          )}

          {isMobile && (
            <IconButton
              onClick={() => setMobileMenuOpen(true)}
              sx={{
                color: theme.palette.common.black,
                background: 'none !important',
                p: '4px',
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {isMobile && (
        <MobileMenu
          isOpen={mobileMenuOpen}
          setIsOpen={setMobileMenuOpen}
          handleLogout={handleLogout}
          shouldDisplayCatalog={(shouldDisplayProducts && !isQorpak()) || isQorpak()}
          iconColor={iconColor}
          catalogHref={catalogHref}
        >
          <Box
            sx={{
              marginTop: '-60px',
              paddingBottom: '1rem',
              padding: '15px 24px',
              backgroundColor: '#F7F9FB',
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: '40px 0px 24px',
              }}
            >
              <span style={{ fontSize: '24px', lineHeight: '120%', fontWeight: 600, color: theme.palette.common.black }}>
                {user?.firstName} {user?.lastName}
              </span>
              <span style={{ fontSize: '16px', lineHeight: '120%', fontWeight: 400, color: '#0179C2', textDecoration: 'underline' }}>
                {user?.company}
              </span>
            </Box>
          </Box>
        </MobileMenu>
      )}
    </>
  )
}

export default Header
