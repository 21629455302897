import Box from '@mui/material/Box'
import MuiStepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { Stack, StepIconProps, useTheme } from '@mui/material'
import { Typography } from '../Typography'
import { CheckIcon } from '../../../components/icons'

type Props = {
  activeStep: number
  steps: string[]
  completed?: boolean[]
}

function CustomStepperIcon({ active, completed }: StepIconProps) {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${active || completed ? theme.palette.common.darkBlue : theme.palette.common.gray600}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: active || completed ? theme.palette.common.darkBlue : theme.palette.common.gray600,
        }}
      ></Stack>
    </Stack>
  )
}

function CustomStepperCompletedIcon() {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: theme.palette.common.darkBlue,
        border: `2px solid ${theme.palette.common.darkBlue}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CheckIcon />
    </Stack>
  )
}

function Stepper({ activeStep, steps, completed }: Props) {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <Box sx={{ width: '100%', maxWidth: '540px' }}>
        <MuiStepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={label}>
              <StepLabel StepIconComponent={completed?.[i] ? CustomStepperCompletedIcon : CustomStepperIcon}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </MuiStepper>
      </Box>
    </Box>
  )
}

export { Stepper }
