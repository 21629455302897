import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IProductCart, IProductSimple } from '../../../types/product'

export interface B2CCartState {
  cartItems: IProductCart[]
  saveForLaterItems: IProductCart[]
  recommendedItems: IProductSimple[]
  perfectMatchItems: IProductSimple[]

  // ui states
  cartDrawerIsOpen: boolean
  emailCartModalIsOpen: boolean
  estimateShippingModalIsOpen: boolean
  taxExemptModalIsOpen: boolean
}

const initialState: B2CCartState = {
  cartItems: [],
  saveForLaterItems: [],
  recommendedItems: [],
  perfectMatchItems: [],

  // ui states
  cartDrawerIsOpen: false,
  emailCartModalIsOpen: false,
  estimateShippingModalIsOpen: false,
  taxExemptModalIsOpen: false,
}

export const b2cCartSlice = createSlice({
  name: 'b2cCartStore',
  initialState,
  reducers: {
    // cart operations
    addToCart: (state, { payload }: PayloadAction<IProductCart>) => {
      if (state.cartItems?.findIndex((item) => item.id === payload.id) === -1) {
        state.cartItems = [...(state.cartItems || []), payload]
      }
    },
    removeFromCart: (state, { payload }: PayloadAction<number>) => {
      if (state.cartItems?.length > 0) {
        state.cartItems = state.cartItems.filter((cartItem) => cartItem.id !== payload)
      }
    },
    updateCartItem: (state, { payload }: PayloadAction<IProductCart>) => {
      state.cartItems = [
        ...state.cartItems.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        }),
      ]
    },
    clearCart: (state) => {
      state.cartItems = []
    },

    // save for later operations
    addToSaveForLater: (state, { payload }: PayloadAction<IProductCart>) => {
      if (state.saveForLaterItems?.findIndex((item) => item.id === payload.id) === -1) {
        state.saveForLaterItems = [...(state.saveForLaterItems || []), payload]
      }
    },
    removeFromSaveForLater: (state, { payload }: PayloadAction<number>) => {
      if (state.saveForLaterItems?.length > 0) {
        state.saveForLaterItems = state.saveForLaterItems.filter((saveForLaterItems) => saveForLaterItems.id !== payload)
      }
    },
    clearSaveForLater: (state) => {
      state.saveForLaterItems = []
    },

    // ui operations
    openCartDrawer: (state) => {
      state.cartDrawerIsOpen = true
    },
    closeCartDrawer: (state) => {
      state.cartDrawerIsOpen = false
    },
    openEmailCartModal: (state) => {
      state.emailCartModalIsOpen = true
    },
    closeEmailCartModal: (state) => {
      state.emailCartModalIsOpen = false
    },
    openEstimateShippingModal: (state) => {
      state.estimateShippingModalIsOpen = true
    },
    closeEstimateShippingModal: (state) => {
      state.estimateShippingModalIsOpen = false
    },
    openTaxExemptModal: (state) => {
      state.taxExemptModalIsOpen = true
    },
    closeTaxExemptModal: (state) => {
      state.taxExemptModalIsOpen = false
    },
  },
})

export const {
  // cart
  addToCart,
  updateCartItem,
  removeFromCart,
  clearCart,

  // save for later
  addToSaveForLater,
  removeFromSaveForLater,
  clearSaveForLater,

  // ui
  openCartDrawer,
  closeCartDrawer,
  openEmailCartModal,
  closeEmailCartModal,
  openEstimateShippingModal,
  closeEstimateShippingModal,
  openTaxExemptModal,
  closeTaxExemptModal,
} = b2cCartSlice.actions
export const { reducer } = b2cCartSlice
