const CatalogIcon = ({ color = '#231F20' }: { color?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <g clipPath="url(#clip0_1664_2010)">
      <path
        d="M2.5 16.3333C3.64014 15.675 4.93347 15.3285 6.25 15.3285C7.56652 15.3285 8.85986 15.675 10 16.3333C11.1401 15.675 12.4335 15.3285 13.75 15.3285C15.0665 15.3285 16.3599 15.675 17.5 16.3333"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5.50005C3.64014 4.84179 4.93347 4.49524 6.25 4.49524C7.56652 4.49524 8.85986 4.84179 10 5.50005C11.1401 4.84179 12.4335 4.49524 13.75 4.49524C15.0665 4.49524 16.3599 4.84179 17.5 5.50005"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.5 5.5V16.3333" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 5.5V16.3333" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 5.5V16.3333" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1664_2010">
        <rect width="20" height="20" fill="none" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export { CatalogIcon }
