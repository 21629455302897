export type ItemStatus = 'In Progress' | 'Action Required' | 'Pending' | 'Approved' | 'Canceled'
export type Counts = {
  Approved: number
  Inactive: number
  'Action Required': number
  Pending: number
  'In Progress': number
}

export type NewItemsData = {
  addDate: string
  code: any
  id: string
  itemFamily: string
  itemGroup: string
  itemName: string
  itemStyle: string
  status: ItemStatus
}

export type NewItems = {
  counts: Counts
  data: {
    items: NewItemsData[]
  }
}

export type Asset = {
  id: string
  file: File
}

export enum AssetUploadStatus {
  idle = 'idle',
  inProgress = 'inProgress',
  completed = 'completed',
  failed = 'failed',
}
export type CheckPointObject = {
  id: number
  name: string
  value: string
}
export type FormValueObj = {
  [key: string]: string | boolean | number | any
}

export type TaxonomyFieldInformation = {
  parentValue: string
  childValues: TaxonomyValues[]
}

export type TaxonomyValues = {
  id: number
  name: string
  value: string
}

export type ItemFieldInformation = {
  pageName: string
  inputFieldDetails: ItemFieldDetails[]
}

export type ItemFieldDetails = {
  id: number
  sequence: number | string
  row: number | string
  column: number | string
  internalName: string
  displayName: string
  helperText: string
  isVisible: boolean
  isMandatory: boolean
  mandatoryCriteria: string
  fieldType: string
  fieldLength: number
  tabName: string
  listOfValues: ListOfValues[]
  uom?: boolean
  uomValues?: ListOfValues[]
}

export type ListOfValues = {
  id: number
  name: string
  value: string
  sequence: string | number
}

export type MoldCode = {
  CODE: string
  NAME: string
  ID: string
  TYPE: string
}
export type Vendor = {
  VENDOR_ID: string
  NAME: string
  VENDOR_NAME: string
  ID: string
  TYPE: string
  CORPORATE_VENDOR_ID: string | number | null
}
export type SupplierPlanner = {
  SUPPLY_PLANNER_BUYER: string
}
export type DemandPlanner = {
  DEMAND_PLANNER: string
}
export type CustomerBU = {
  CUSTOMER_BU: string
  CUSTOMER_NAME: string
  CUSTOMER_ID: string
  SUPPORT_TEAM_CD: string
  SUPPORT_TEAM_NAME: string
}
export type HSCode = {
  HS_VALUE: string
  HS_NAME: string
  HS_CODE: string
  DUTY_RATE: string
}

export type HTSCode = {
  HTS_DETAIL: string
  HTS_VALUE: string
  TARIFF_DUTY_RATE: string
  HTS_NAME: string
}
