const ChevronLeftIcon = () => (
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.22168 2.20605L3.62793 6.7998L8.22168 11.3936L6.81543 12.7998L0.81543 6.7998L6.81543 0.799805L8.22168 2.20605Z"
      fill="black"
    />
  </svg>
)

export { ChevronLeftIcon }
