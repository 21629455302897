import { Stack, useTheme } from '@mui/material'
import { Modal, Typography } from '../common'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { setMinQtyModal } from '../../stores/ProductsStore/productsSlice'
import { selectDisplayProductMinimumQtyModal } from '../../stores/ProductsStore/selectors'

export const ProductMinimumModal = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const displayProductMinimumQtyModal = useAppSelector(selectDisplayProductMinimumQtyModal)

  return (
    <Modal
      open={displayProductMinimumQtyModal != null}
      title=""
      titleAlign="start"
      maxHeight="80vh"
      onClose={() => {
        dispatch(setMinQtyModal(null))
      }}
      actions={<></>}
    >
      <Stack spacing={2}>
        {displayProductMinimumQtyModal && (
          <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 500, color: theme.palette.common.black }}>
            {/* Minimum quantity to purchase for this product (SKU = {displayProductMinimumQtyModal.sku}) is {displayProductMinimumQtyModal.minQty} */}
            {displayProductMinimumQtyModal.message}
          </Typography>
        )}
      </Stack>
    </Modal>
  )
}
