export const CalendarIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_856_8587)">
      <path
        d="M38.0001 6H36.0001V2H32.0001V6H16.0001V2H12.0001V6H10.0001C7.78012 6 6.02012 7.8 6.02012 10L6.00012 38C6.00012 40.2 7.78012 42 10.0001 42H38.0001C40.2001 42 42.0001 40.2 42.0001 38V10C42.0001 7.8 40.2001 6 38.0001 6ZM38.0001 38H10.0001V16H38.0001V38ZM14.0001 20H24.0001V30H14.0001V20Z"
        fill="#DBEFFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_856_8587">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
