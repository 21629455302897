import { Box, SxProps, Theme, useTheme } from '@mui/material'

import { Button } from '../Button'
import { ChevronLeftIcon, ChevronRightIcon, LastPageIcon, FirstPageIcon } from '../../icons'

type Props = {
  currentPage: number
  totalPages?: number
  onSelectPage?: (page: number) => void
  onNext?: () => void
  onPrev?: () => void
}

const Pagination = ({ currentPage, totalPages = 6, onPrev = () => {}, onNext = () => {}, onSelectPage = () => {} }: Props) => {
  const theme = useTheme()
  const defaultSx: SxProps<Theme> = {
    backgroundColor: 'white',
    p: 1,
    width: 40,
    mx: 0.625,
    boxShadow: 'none',
    ':hover': { bgcolor: theme.palette.common.cyanBlue, '& path': { fill: 'white' } },
  }

  return (
    <Box display="flex" sx={{ overflowX: 'auto' }} py={1}>
      <Button
        color="secondary"
        label={<FirstPageIcon />}
        variant="contained"
        sx={defaultSx}
        disabled={currentPage === 1}
        onClick={() => onSelectPage(1)}
      />
      <Button
        color="secondary"
        label={<ChevronLeftIcon />}
        disabled={currentPage === 1}
        variant="contained"
        sx={defaultSx}
        onClick={onPrev}
      />
      {Array.from(Array(totalPages).keys())
        .map((_, i) => i + 1)
        .map((page) =>
          currentPage === page ? (
            <Button
              key={page}
              color="secondary"
              label={page}
              variant="contained"
              sx={{
                color: theme.palette.common.white,
                bgcolor: theme.palette.common.blue,
                p: 1,
                mx: 0.625,
                boxShadow: 'none',
                ':hover': { bgcolor: theme.palette.common.cyanBlue },
              }}
              onClick={() => onSelectPage(page)}
            />
          ) : Math.abs(page - currentPage) < 2 ||
            (page === 3 && currentPage === 1) ||
            (currentPage === totalPages && page === currentPage - 2) ? (
            <Button
              key={page}
              label={page}
              variant="contained"
              sx={{
                ...defaultSx,
                color: theme.palette.common.text,
                ':hover': { color: theme.palette.common.white, bgcolor: theme.palette.common.cyanBlue },
              }}
              onClick={() => onSelectPage(page)}
            />
          ) : null
        )}
      <Button
        label={<ChevronRightIcon />}
        color="secondary"
        variant="contained"
        disabled={currentPage >= totalPages}
        sx={defaultSx}
        onClick={onNext}
      />
      <Button
        label={<LastPageIcon />}
        disabled={currentPage >= totalPages}
        color="secondary"
        variant="contained"
        sx={{ ...defaultSx, mr: 1 }}
        onClick={() => onSelectPage(totalPages)}
      />
    </Box>
  )
}

export { Pagination }
