import { useCallback, useState } from 'react'
import { Box, Button, Slider, Typography, useTheme } from '@mui/material'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'

import getCroppedImg from './cropImage'

import { Modal } from '../Modal'

type Props = {
  image: string
  onSave: (image: string) => void
  onClose: () => void
}

const ImageCropper = ({ image, onSave, onClose }: Props) => {
  const theme = useTheme()
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [areaPixels, setAreaPixels] = useState<Area | null>(null)

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setAreaPixels(croppedAreaPixels)
  }, [])

  const handleSave = async () => {
    if (!areaPixels) {
      onSave(image)
      return
    }
    const croppedImg = await getCroppedImg(image, areaPixels, rotation)
    onSave(croppedImg || image)
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      title="Crop Image"
      actions={
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      }
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: 300,
          overflow: 'hidden',
        }}
      >
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
      <Box sx={{ px: 2, py: 3 }}>
        <Typography variant="overline" sx={{ sx: theme.palette.common.black }}>
          Zoom
        </Typography>
        <Slider value={zoom} min={1} max={5} step={0.1} onChange={(e, value) => setZoom(value as number)} />
        <Typography variant="overline" sx={{ sx: theme.palette.common.black }}>
          Rotation
        </Typography>
        <Slider value={rotation} min={0} max={360} step={1} onChange={(e, value) => setRotation(value as number)} />
      </Box>
    </Modal>
  )
}

export default ImageCropper
