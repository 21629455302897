import { PropsWithChildren, useContext, createContext, useState, useMemo } from 'react'

type AuthContextState = {
  token: string | null | undefined
  user: UserInfo | undefined
  setUserInfo: (p: UserInfo) => void
  setToken: (t: string | null) => void
}

export type UserInfo = {
  firstName: string
  lastName: string
  email: string
  userId: string
  company?: string
  profileImg?: string
  newUser?: boolean
}

const AuthContext = createContext<AuthContextState>(null as never)

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [token, setToken] = useState<string | null>()
  const [userInfo, setUserInfo] = useState<UserInfo>()

  const values = useMemo(() => ({ token, user: userInfo, setUserInfo, setToken }), [token, userInfo])

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
export default AuthContext
