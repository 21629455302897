import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store, persistor } from './stores/rootStore'
import { msalConfig, msalQorpakConfig } from './authConfig'
import { isQorpak } from './utils/common'
import { getCookie } from './utils/cookie'

export const msalInstance = new PublicClientApplication(isQorpak() ? msalQorpakConfig : msalConfig)

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts()
  const userId = getCookie('ajs_user_id')

  if (accounts.length > 0 && userId) {
    if (accounts[0].localAccountId === userId) {
      msalInstance.setActiveAccount(accounts[0])
    } else {
      window.localStorage.removeItem('token')
      window.sessionStorage.clear() //clearing cache if different is user present
    }
  }
})

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line  @typescript-eslint/no-var-requires
  // const { worker } = require('./mocks/browser')
  // worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App instance={msalInstance} />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
