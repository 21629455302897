import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProductSimple } from '~types/product'

export const CompareProductLimit = 3
export interface CompareProductsState {
  selectedProducts: IProductSimple[]
  compareModalIsOpened: boolean
}

const initialState: CompareProductsState = {
  selectedProducts: [],
  compareModalIsOpened: false,
}

export const compareProductsSlice = createSlice({
  name: 'compareProductsStore',
  initialState,
  reducers: {
    toggleSelectedProducts: (state, { payload }: PayloadAction<IProductSimple>) => {
      const productIndex = state.selectedProducts.findIndex((product) => product.id === payload.id)
      if (productIndex >= 0) {
        state.selectedProducts.splice(productIndex, 1)
      } else if (state.selectedProducts.length < CompareProductLimit) {
        state.selectedProducts.push(payload)
      }
    },
    updateSelectedProducts: (state, { payload }: PayloadAction<IProductSimple>) => {
      if (
        state.selectedProducts.length < CompareProductLimit &&
        state.selectedProducts.findIndex((product) => product.id === payload.id) === -1
      ) {
        state.selectedProducts.push(payload)
      }
    },
    removeSelectedProduct: (state, { payload }: PayloadAction<number>) => {
      const productIndex = state.selectedProducts.findIndex((product) => product.id === payload)
      if (productIndex >= 0) {
        state.selectedProducts.splice(productIndex, 1)
      }
    },
    openCompareModal: (state) => {
      state.compareModalIsOpened = true
    },
    closeCompareModal: (state) => {
      state.compareModalIsOpened = false
    },
    resetCompareProducts: (state) => {
      state.selectedProducts = []
      state.compareModalIsOpened = false
    },
  },
})

export const {
  toggleSelectedProducts,
  updateSelectedProducts,
  removeSelectedProduct,
  openCompareModal,
  closeCompareModal,
  resetCompareProducts,
} = compareProductsSlice.actions
export const { reducer } = compareProductsSlice
