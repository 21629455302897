import React, { useEffect } from 'react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { visuallyHidden } from '@mui/utils'
import { useTheme, Skeleton } from '@mui/material'

import { Modal, ModalProps } from '../Modal'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // add processing for null/undefined values to be at the bottom
  // if ascending and at the top when descending
  if (!a[orderBy] && !b[orderBy]) {
    return 0
  }
  if (!a[orderBy]) {
    return orderBy ? -1 : 1
  }
  if (!b[orderBy]) {
    return orderBy ? 1 : -1
  }

  if ((b[orderBy] ?? 0) < (a[orderBy] ?? 0)) {
    return -1
  }
  if ((b[orderBy] ?? 0) > (a[orderBy] ?? 0)) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const rightStickyStyle = {
  position: 'sticky',
  right: 0,
  zIndex: 1,
}
const leftStickyStyle = {
  position: 'sticky',
  left: 0,
  zIndex: 1,
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getRows<T>(rows: T[], skipSort: boolean, order: Order, orderBy: any) {
  if (skipSort) {
    return rows as readonly { [x: string]: string | number }[]
  } else {
    return stableSort(rows as readonly { [x: string]: string | number }[], getComparator(order, orderBy as unknown as string))
  }
}

export interface HeadCell<T> {
  cellPadding?: string
  disablePadding: boolean
  id: keyof T
  label: string | React.ReactNode
  numeric: boolean
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  width?: string
  disableSort?: boolean
  hasChild?: boolean
  hideBorder?: boolean
  renderer?: (payload: T) => React.ReactNode
}

interface EnhancedTableHeadProps<T> {
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: readonly HeadCell<T>[]
  selectableRow?: boolean
  hasStickyColumns?: boolean
  hideBorder?: boolean
}

function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
  const theme = useTheme()

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, hasStickyColumns = false, onRequestSort } = props
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{ border: !props.hideBorder ? `1px solid ${theme.palette.common.gray700}` : 'none' }}>
      <TableRow>
        {props.selectableRow && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id as unknown as string}
            align={headCell.align === 'center' ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={!headCell.disableSort && orderBy === headCell.id ? order : false}
            sx={{
              whiteSpace: 'nowrap',
              ...(hasStickyColumns && i === 0 ? ({ ...leftStickyStyle, background: theme.palette.common.white } as any) : {}),
              ...(hasStickyColumns && i === props.headCells.length - 1
                ? ({ ...rightStickyStyle, background: theme.palette.common.white } as any)
                : {}),
            }}
          >
            <TableSortLabel
              hideSortIcon={headCell.disableSort}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              sx={{ fontWeight: 'bold', fontSize: 16, lineHeight: '19px', color: theme.palette.common.black }}
              onClick={headCell.disableSort ? undefined : createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && !headCell.disableSort ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  numSelected: number
  title?: string
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, title } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : title ? (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      ) : null}
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  )
}

type TableProps<T> = {
  columns: readonly HeadCell<T>[]
  rows: T[]
  hasToolbar?: boolean
  showPagination?: boolean
  selectableRow?: boolean
  isActionHoverable?: boolean
  size?: 'small' | 'medium'
  title?: string
  emptyStateText?: React.ReactNode
  perPage?: number
  sortBy?: string
  sortOrder?: Order
  skipLocalSort?: boolean
  currentPage?: number
  isLoading?: boolean
  hasStickyColumns?: boolean
  onActionCallback?: (open: boolean, id: string | number) => void
  extractorKey: keyof T
  onSelectRow?: (id: string) => void
  onSelectChange?: (selected: readonly string[]) => void
  onSortChange?: (property?: any, key?: string) => void
  hasModal?: boolean
  modalProps?: ModalProps
  hideBorder?: boolean
  backgroundColor?: string
}

type ShowAction = {
  id: string | number
  open: boolean
}

export function EnhancedTable<T extends object>({
  title,
  columns,
  rows,
  hasToolbar = true,
  showPagination = true,
  selectableRow = true,
  isActionHoverable = false,
  size = 'medium',
  emptyStateText,
  perPage,
  currentPage,
  isLoading,
  sortBy = '',
  sortOrder = 'asc',
  hasStickyColumns = false,
  onActionCallback = () => {},
  extractorKey,
  onSelectRow = () => {},
  onSelectChange = () => {},
  onSortChange,
  skipLocalSort = false,
  hasModal = false,
  hideBorder,
  backgroundColor,
  modalProps = { open: false, title: '', children: <></>, actions: <></>, onClose: () => {} },
}: TableProps<T>) {
  const theme = useTheme()

  const [order, setOrder] = React.useState<Order>(sortOrder)
  const [orderBy, setOrderBy] = React.useState<keyof T | string>(sortBy)
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage ?? 5)
  const [showAction, setShowAction] = React.useState<ShowAction>({
    id: '',
    open: false,
  })
  useEffect(() => {
    setRowsPerPage(perPage ?? 5)
  }, [perPage])

  useEffect(() => {
    setPage(currentPage ?? 0)
  }, [currentPage])

  useEffect(() => {
    onSelectChange?.(selected)
  }, [selected, onSelectChange])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    onSortChange?.(property, isAsc ? 'desc' : 'asc')
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[extractorKey] as unknown as string)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    if (!!onSelectRow) {
      const selectedIndex = selected.indexOf(name)
      let newSelected: readonly string[] = []
      if (selectedIndex === -1) {
        newSelected = [name]
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      }

      setSelected(newSelected)

      onSelectRow?.(name)
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const onMouseEnter = React.useCallback(
    (id: string | number, val: boolean) => () => {
      if (isActionHoverable) {
        setShowAction({ id: id, open: val })
        onActionCallback(val, id)
      }
    },
    [setShowAction, onActionCallback]
  )

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <>
      {hasModal && <Modal {...modalProps}>{modalProps?.children}</Modal>}
      {hasToolbar && <EnhancedTableToolbar title={title} numSelected={selected.length} />}
      <TableContainer sx={{ bgcolor: theme.palette.common.white }}>
        <Table aria-labelledby="tableTitle" size={size}>
          <EnhancedTableHead<T>
            numSelected={selected.length}
            order={order}
            orderBy={(orderBy as unknown as string) ?? ''}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={columns}
            selectableRow={selectableRow}
            hasStickyColumns={hasStickyColumns}
            hideBorder={hideBorder}
          />
          <TableBody
            sx={{
              ...(rows.length === 0
                ? {}
                : {
                    borderColor: theme.palette.common.gray700,
                    borderWidth: hideBorder ? '1px 0 1px 0' : '1px',
                  }),
              '& .Mui-selected': {
                bgcolor: theme.palette.common.white,
              },
            }}
          >
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {rows.length === 0 || isLoading ? (
              isLoading ? (
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((rowId) => (
                  <TableRow key={rowId}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id as unknown as string}
                        sx={{ fontSize: 18, lineHeight: '24px', color: theme.palette.common.text }}
                        align={column.align}
                      >
                        <Skeleton variant="rectangular" height={19} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ bgcolor: backgroundColor ?? theme.palette.common.lightGray, border: 'none' }}>
                  <TableCell colSpan={columns.length} sx={{ border: 'none' }}>
                    <Box display="flex" justifyContent={title === 'Recent orders' ? 'left' : 'center'}>
                      <Typography sx={{ color: title === 'Recent orders' ? theme.palette.common.textSecondary : '#616161' }}>
                        {emptyStateText ?? 'No Data.'}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            ) : (
              getRows(rows, skipLocalSort, order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row[extractorKey] as unknown as string)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row[extractorKey] as unknown as string)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        aria-label={labelId}
                        tabIndex={-1}
                        key={(row as T)[extractorKey] as unknown as string}
                        selected={isItemSelected}
                        onMouseEnter={onMouseEnter(row.id, true)}
                        /**
                         * Please don't remove, kept for future use
                         */
                        // onMouseLeave={() => {
                        //   if (isActionHoverable) {
                        //     setShowAction({ id: '', open: false })
                        //     onActionCallback(false, '')
                        //   }
                        // }}
                      >
                        {selectableRow && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                        )}
                        {columns.map((column, i) => (
                          <TableCell
                            key={column.id as unknown as string}
                            sx={{
                              fontSize: 18,
                              lineHeight: '24px',
                              color: theme.palette.common.text,
                              padding: column.cellPadding,
                              whiteSpace: 'nowrap',
                              ...(hasStickyColumns && i === 0
                                ? ({ ...leftStickyStyle, background: theme.palette.common.white } as any)
                                : {}),
                              ...(hasStickyColumns && i === columns.length - 1
                                ? ({ ...rightStickyStyle, background: theme.palette.common.white } as any)
                                : {}),
                            }}
                            align={column.align}
                            width={column.width}
                          >
                            {typeof column.renderer === 'function'
                              ? column.renderer({ ...row, selectedId: showAction.id } as T)
                              : row[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                      {/* Custom code to support kits components */}
                      {Array.isArray(row.productComponents)
                        ? row.productComponents.map((component) => (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, component[extractorKey] as unknown as string)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              aria-label={labelId}
                              tabIndex={-1}
                              key={(component as T)[extractorKey] as unknown as string}
                              selected={isItemSelected}
                              onMouseEnter={onMouseEnter(component.id, true)}
                              /**
                               * Please don't remove, kept for future use
                               */
                              // onMouseLeave={() => {
                              //   if (isActionHoverable) {
                              //     setShowAction({ id: '', open: false })
                              //     onActionCallback(false, '')
                              //   }
                              // }}
                            >
                              {columns.map((column, i) => (
                                <TableCell
                                  key={column.id as unknown as string}
                                  sx={{
                                    fontSize: 18,
                                    lineHeight: '24px',
                                    color: theme.palette.common.text,
                                    padding: column.cellPadding,
                                    whiteSpace: 'nowrap',
                                    ...(i === 0
                                      ? {
                                          paddingLeft: 5,
                                        }
                                      : {}),
                                    ...(hasStickyColumns && i === 0
                                      ? ({ ...leftStickyStyle, background: theme.palette.common.white } as any)
                                      : {}),
                                    ...(hasStickyColumns && i === columns.length - 1
                                      ? ({ ...rightStickyStyle, background: theme.palette.common.white } as any)
                                      : {}),
                                  }}
                                  align={column.align}
                                  width={column.width}
                                >
                                  {column.hasChild
                                    ? typeof column.renderer === 'function'
                                      ? column.renderer({ ...component, selectedId: showAction.id } as T)
                                      : component[column.id]
                                    : ''}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        : null}
                    </>
                  )
                })
            )}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}
