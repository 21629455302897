import { IconLogout } from '@tabler/icons'

const signOut = {
  id: 'signout',
  title: 'Sign Out',
  type: 'item',
  url: '',
  icon: IconLogout,
  breadcrumbs: false,
}

export default signOut
