import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  TaxonomyFieldInformation,
  ItemFieldDetails,
  NewItems,
  Asset,
  AssetUploadStatus,
  MoldCode,
  Vendor,
  CustomerBU,
  SupplierPlanner,
  DemandPlanner,
  HSCode,
  HTSCode,
} from '../../types/newitem'

export interface TaxonomyState {
  taxonomyList: TaxonomyFieldInformation[]
  attributeList: { [key: string]: ItemFieldDetails[] }
  azureId: number
  dashboardItem: NewItems
  assets: Asset[]
  assetUploadStatus: AssetUploadStatus
  riversandItem: any
  payload: any
  newitem: any
  moldCode: MoldCode[]
  vendor: Vendor[]
  customerBu: CustomerBU[]
  supplierPlanner: SupplierPlanner[]
  demandPlanner: DemandPlanner[]
  hsCode: HSCode[]
  htsCode: HTSCode[]
}

const initialState: TaxonomyState = {
  taxonomyList: [],
  attributeList: {},
  azureId: 0,
  dashboardItem: {
    counts: {
      Approved: 0,
      Inactive: 0,
      'Action Required': 0,
      Pending: 0,
      'In Progress': 0,
    },
    data: {
      items: [],
    },
  },
  assets: [],
  assetUploadStatus: AssetUploadStatus.idle,
  riversandItem: {},
  payload: {},
  newitem: {},
  moldCode: [],
  vendor: [],
  customerBu: [],
  supplierPlanner: [],
  demandPlanner: [],
  hsCode: [],
  htsCode: [],
}

export const newItemSlice = createSlice({
  name: 'newItemStore',
  initialState,
  reducers: {
    setDashboardItems: (state, { payload }: PayloadAction<NewItems>) => {
      state.dashboardItem = payload
    },
    setTaxonomy: (state, { payload }: PayloadAction<TaxonomyFieldInformation[]>) => {
      state.taxonomyList = payload
    },
    setRiversandItem: (state, { payload }: PayloadAction<any>) => {
      state.riversandItem = payload
    },
    setAttributes: (state, { payload }: PayloadAction<{ [key: string]: ItemFieldDetails[] }>) => {
      state.attributeList = payload
    },
    setNewItems: (state, { payload }: PayloadAction<TaxonomyFieldInformation[]>) => {
      state.taxonomyList = payload
    },
    setNewItem: (state, { payload }: PayloadAction<TaxonomyFieldInformation[]>) => {
      state.newitem = payload
    },
    setAzureId: (state, { payload }: PayloadAction<any>) => {
      state.azureId = payload.azureId
    },
    setPayload: (state, { payload }: PayloadAction<any>) => {
      state.payload = payload
    },
    setNewAsset: (state, { payload }: PayloadAction<Asset>) => {
      state.assets = [...state.assets, payload]
    },
    setMoldCode: (state, { payload }: PayloadAction<MoldCode[]>) => {
      state.moldCode = payload
    },
    setVendor: (state, { payload }: PayloadAction<Vendor[]>) => {
      state.vendor = payload
    },
    setCustomerBu: (state, { payload }: PayloadAction<CustomerBU[]>) => {
      state.customerBu = payload
    },
    setSupplierPlanner: (state, { payload }: PayloadAction<SupplierPlanner[]>) => {
      state.supplierPlanner = payload
    },
    setDemandPlanner: (state, { payload }: PayloadAction<DemandPlanner[]>) => {
      state.demandPlanner = payload
    },
    setHSCode: (state, { payload }: PayloadAction<HSCode[]>) => {
      state.hsCode = payload
    },
    setHTSCode: (state, { payload }: PayloadAction<HTSCode[]>) => {
      state.htsCode = payload
    },
    resetData: (state) => {
      state.assets = []
      state.payload = {}
      state.azureId = 0
      state.attributeList = {}
      state.riversandItem = []
      state.newitem = {}
      state.moldCode = []
      state.vendor = []
      state.customerBu = []
      state.supplierPlanner = []
      state.demandPlanner = []
      state.hsCode = []
      state.htsCode = []
      state.assetUploadStatus = AssetUploadStatus.idle
    },
    setAssetUploadStatus: (state, { payload }: PayloadAction<AssetUploadStatus>) => {
      state.assetUploadStatus = payload
    },
  },
})

export const {
  setTaxonomy,
  setRiversandItem,
  setAttributes,
  setNewItems,
  setNewItem,
  setAzureId,
  setPayload,
  setDashboardItems,
  setNewAsset,
  resetData,
  setAssetUploadStatus,
  setMoldCode,
  setCustomerBu,
  setDemandPlanner,
  setHSCode,
  setHTSCode,
  setSupplierPlanner,
  setVendor,
} = newItemSlice.actions
export const { reducer } = newItemSlice
