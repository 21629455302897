import { Action, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import logger from 'redux-logger'

import { persistedReducer, RootState } from './rootReducer'

const resetType = 'RESET_STORE'

export const resetAction = createAction(resetType)

function reducer(state: RootState | undefined, action: any) {
  if (action.type === resetType) {
    state = undefined
  }

  return persistedReducer(state, action)
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({ serializableCheck: false }).concat(logger)
      : getDefaultMiddleware({
          serializableCheck: false,
        }),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppThunk<T = void> = ThunkAction<T, RootState, null, Action<string>>
