import { Typography as MuiTypography, useTheme } from '@mui/material'

import HighPriorityIcon from '../../../assets/images/icons/high-priority.svg'

import type { TypographyProps } from '@mui/material'

type Props = TypographyProps & {
  type?: 'text' | 'title'
  component?: 'p' | 'div'
}

const Typography = ({ children, ...props }: Props) => {
  const theme = useTheme()

  // Mock Data
  if (typeof children === 'string' && children.startsWith('mock - [')) {
    return (
      <MuiTypography
        display="flex"
        alignItems="center"
        sx={{ fontSize: 16, lineHeight: '24px', fontWeight: 400, fontStyle: 'italic', color: theme.palette.common.red }}
      >
        <img src={HighPriorityIcon} alt="mock-data" />
        &nbsp;&nbsp;
        {children}
      </MuiTypography>
    )
  }

  return (
    <MuiTypography
      {...{
        ...props,
        sx:
          props.type === 'text'
            ? { fontSize: 16, lineHeight: '19px', fontWeight: '400', color: theme.palette.common.black, ...props.sx }
            : props.sx,
      }}
    >
      {children}
    </MuiTypography>
  )
}

export { Typography }
