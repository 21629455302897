import { isQorpak } from '../utils/common'

const Logo = () => {
  if (isQorpak()) {
    return (
      <img
        src="https://cdn.berlinpackaging.com/image/upload/v1692376067/Content%20Assets/Qorpak/Logo/qorpak-logo.png"
        alt="logo"
        style={{ width: '150px', height: '100%' }}
      />
    )
  } else {
    return (
      <img
        src="https://cdn.berlinpackaging.com/image/upload/v1690418121/Content%20Assets/Berlin%20Packaging/Logo/Berlin-Packaging-Logo.svg"
        alt="logo"
        style={{ width: '88px', height: '100%' }}
      />
    )
  }
}

export default Logo
