import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
// assets
import { IconLayoutGrid } from '@tabler/icons'

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
const useDashboardMenuItems = () => {
  const { t } = useTranslation()

  const dashboard = useMemo(
    () => ({
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: 'dashboard/default',
      icon: IconLayoutGrid,
      breadcrumbs: false,
    }),
    [t]
  )
  return { dashboard }
}

export { useDashboardMenuItems }
