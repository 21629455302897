import { Box, Link, Typography, useTheme } from '@mui/material'
import React from 'react'
import CopyrightIcon from '@mui/icons-material/Copyright'

import { getLowerEnv, isProduction } from '../../../utils/common'

const ENV_URL: any = {
  qorpak: {
    privacy: 'shop.qorpak.com/privacy-policy',
    tacs: 'shop.qorpak.com/legal',
  },
  berlin: {
    privacy: 'shop.berlinpackaging.com/privacy-policy',
    tacs: 'shop.berlinpackaging.com/terms-and-conditions',
  },
}

const Footer = ({ platform }: { platform: string }): React.ReactElement => {
  const theme = useTheme()
  const platformLabel = platform === 'qorpak' ? 'Qorpak' : 'Berlin Packaging'
  const year = new Date().getFullYear()

  const footerLinks: { text: string; link: string }[] = [
    { text: 'Privacy Policy', link: `https://${isProduction() ? '' : getLowerEnv() + '.'}${ENV_URL[platform].privacy}` },
    { text: 'Terms & Conditions', link: `https://${isProduction() ? '' : getLowerEnv() + '.'}${ENV_URL[platform].tacs}` },
    // { text: 'Share Feedback', link: 'https://form.asana.com/?k=cfiMCp79h5a0xy-3e-cFAg&d=303981102506080' },
  ]

  return (
    <Box
      sx={{
        width: '100%',
        borderTop: '1px solid #EEE',
        minHeight: '91px',
        mt: '45px',
      }}
    >
      <Box
        sx={{
          py: '32px',
          maxWidth: '1440px',
          width: '100%',
          margin: 'auto',
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          gap: '30px',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'center',
          [theme.breakpoints.down(1449)]: {
            px: '16px',
          },
        }}
      >
        <Box
          component="span"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '4px',
            flexShrink: 0,
            flexGrow: 0,
            textAlign: 'center',
          }}
        >
          <CopyrightIcon sx={{ fontSize: '14px' }} />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
            }}
          >
            {year} {platformLabel}. All Rights Reserved.
          </Typography>
        </Box>

        {/* links */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            columnGap: '20px',
            rowGap: '10px',
            flexShrink: 1,
          }}
        >
          {footerLinks.map((footerLink) => (
            <Link
              key={footerLink.link}
              href={footerLink.link}
              target="_blank"
              sx={{
                color: theme.palette.common.blue,
                textDecorationColor: theme.palette.common.blue,
                '&:hover': {
                  color: theme.palette.common.blue,
                  textDecorationColor: theme.palette.common.blue,
                },
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              {footerLink.text}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
