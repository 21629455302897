export const BoltIcon = () => (
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_856_8442)">
      <path
        d="M22.3332 42H20.3332L22.3332 28H15.3332C14.1732 28 14.1932 27.36 14.5732 26.68C14.9532 26 14.6732 26.52 14.7132 26.44C17.2932 21.88 21.1732 15.08 26.3332 6H28.3332L26.3332 20H33.3332C34.3132 20 34.4532 20.66 34.2732 21.02L34.1332 21.32C26.2532 35.1 22.3332 42 22.3332 42Z"
        fill="#DBEFFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_856_8442">
        <rect width="48" height="48" fill="white" transform="translate(0.333252)" />
      </clipPath>
    </defs>
  </svg>
)
