import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import { Chip, Grid, Stack, TextField, useTheme } from '@mui/material'

import { Typography, Button } from '../../../common'
import { CrossIcon, HeartIcon, InfoRoundIcon } from '../../../icons'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../hooks/reduxHooks'
import { addToSaveForLater, removeFromCart, updateCartItem } from '../../../../stores/b2c/CartStore/cartSlice'
import { IProductCart } from '../../../../types/product'

type Props = {
  data: IProductCart
  size: 'small' | 'default'
  editDisabled?: boolean
}

const B2CCartItem = React.memo(({ data, size, editDisabled }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [quantity, setQuantity] = useState(data.quantity)

  const remove = () => {
    if (data.id) {
      dispatch(removeFromCart(data.id))
    }
  }

  const saveForLater = () => {
    if (data.id) {
      // remove from cart and add to save for later
      dispatch(removeFromCart(data.id))
      dispatch(addToSaveForLater(data))
    }
  }

  useEffect(() => {
    setQuantity(data.quantity)
  }, [data])
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const qty = +evt.target.value
    setQuantity(qty)
    dispatch(
      updateCartItem({
        ...data,
        quantity: qty,
      })
    )
  }
  return (
    <Grid container spacing={2} p={3}>
      <Grid item xs={size === 'small' ? 3 : 2}>
        <img src={data.properties.thumbnail} alt={data.properties.name} style={{ width: '100%' }} />
      </Grid>
      <Grid container item xs={size === 'small' ? 9 : 10} spacing={1}>
        <Grid item xs={size === 'small' ? 9 : 6}>
          <Stack spacing={1}>
            <Stack>
              <Typography variant="h4" sx={{ fontSize: 18 }}>
                {data.properties.description}
              </Typography>
              <Typography sx={{ fontSize: 16 }}>
                {t('product.berlin_item')} {data.properties.sku}
              </Typography>
              {data.properties.alias ? (
                <Typography sx={{ fontSize: 16 }}>
                  {t('product.your_item')}
                  {data.properties.alias}
                </Typography>
              ) : (
                ''
              )}
            </Stack>
            <Stack display={size === 'small' ? 'none' : 'flex'}>
              <Typography sx={{ fontSize: 16 }}>
                {t('cart.product_card.capacity')}: {data.properties.capacity || '-'}
              </Typography>
              {/* {data.quantitySize && (
                <Typography sx={{ fontSize: 16 }}>
                  {t('cart.product_card.quantity_size')}: {data.quantitySize}
                </Typography>
              )}
              <Typography sx={{ fontSize: 16 }}>
                {t('cart.product_card.total_units')}: {data.totalUnits}
              </Typography> */}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2} display={size === 'small' ? 'none' : 'flex'}>
          <TextField
            id="outlined-basic"
            type="number"
            variant="outlined"
            name="quantity"
            value={quantity}
            onChange={handleChange}
            disabled={editDisabled}
          />
        </Grid>
        <Grid item xs={2} display={size === 'small' ? 'none' : 'flex'} justifyContent="flex-end">
          <Typography variant="h4">{numeral(data.properties.retailPrice).format('$0,0.0000')}</Typography>
        </Grid>
        <Grid item xs={2} display={size === 'small' ? 'none' : 'flex'} justifyContent="flex-end">
          <Typography variant="h4">{numeral(data.properties.retailPrice * quantity).format('$0,0.0000')}</Typography>
        </Grid>

        <Grid item xs={3} display={size === 'small' ? 'flex' : 'none'}>
          <Stack spacing={0.5} alignItems="flex-end">
            <Typography variant="h4" sx={{ color: theme.palette.common.darkGreen }}>
              {numeral(data.properties.retailPrice * quantity).format('$0,0.0000')}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              {numeral(data.properties.retailPrice).format('$0,0.0000')}/{t('cart.product_card.each')}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} display={size === 'small' ? 'none' : 'flex'}>
          <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
            {/* statuses - this will be rendered depending on status flags on the product data */}
            <Chip
              label={t('cart.product_card.status.ships_within_3_to_5_business_days')}
              sx={{ borderRadius: 0, background: theme.palette.common.green700, color: theme.palette.common.darkGreen }}
            />
            <Chip
              label={t('cart.product_card.status.limited_stock')}
              sx={{ borderRadius: 0, background: theme.palette.common.gray700, color: theme.palette.common.darkGray }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} display={size === 'small' ? 'none' : 'flex'}>
          <Stack direction="row" alignItems="center">
            <Button
              label={t('cart.product_card.buttons.save_for_later')}
              variant="text"
              size="small"
              sx={{ color: theme.palette.common.darkBlue, fontSize: 16, mr: 1 }}
              onClick={saveForLater}
            />
            <Button label={<HeartIcon stroke={theme.palette.common.darkBlue} />} variant="text" size="small" />
            {!editDisabled && (
              <Button label={<CrossIcon stroke={theme.palette.common.darkBlue} />} variant="text" size="small" onClick={remove} />
            )}
            {/* alert - this will be rendered depending on a flag on the product data */}
            <Chip
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Chip
                    label={
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography sx={{ fontWeight: 'bold' }}>{t('cart.product_card.alerts.ship_as_available.title')}</Typography>
                        <InfoRoundIcon />
                      </Stack>
                    }
                    sx={{ borderRadius: 1, background: theme.palette.common.darkBlue, color: theme.palette.common.white, height: '26px' }}
                  />
                  <Typography sx={{ pr: 1 }}>{t('cart.product_card.alerts.ship_as_available.description')}</Typography>
                </Stack>
              }
              sx={{
                borderRadius: 1,
                background: theme.palette.common.blue700,
                color: theme.palette.common.darkBlue,
                '> .MuiChip-label': {
                  padding: 0.5,
                },
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} display={size === 'small' ? 'flex' : 'none'}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <TextField
                fullWidth={true}
                id="outlined-basic"
                size="small"
                type="number"
                variant="outlined"
                name="quantity"
                value={quantity}
                onChange={handleChange}
                disabled={editDisabled}
              />
            </Grid>
            <Grid item xs={8} alignItems="center">
              <Stack spacing={1} direction="row" alignItems="center">
                <Button
                  label={t('cart.product_card.buttons.save_for_later')}
                  variant="text"
                  size="small"
                  sx={{ color: theme.palette.common.darkBlue, fontSize: 11, mr: 1 }}
                  onClick={saveForLater}
                />
                {!editDisabled && (
                  <Button
                    label={t('cart.product_card.buttons.remove')}
                    variant="text"
                    size="small"
                    sx={{ color: theme.palette.common.darkBlue, fontSize: 11, fontWeight: 'normal' }}
                    onClick={remove}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

export { B2CCartItem }
