import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash'

import { Requests } from '../../types/common'

export interface LoadingState {
  requests: Record<Requests, boolean>
}

const initialState: LoadingState = {
  requests: {} as Record<Requests, boolean>,
}

export const loadingSlice = createSlice({
  name: 'loadingStore',
  initialState,
  reducers: {
    addRequest: (state, { payload }: PayloadAction<Requests>) => {
      state.requests[payload] = true
    },
    removeRequest: (state, { payload }: PayloadAction<Requests>) => {
      state.requests = omit(state.requests, [payload]) as Record<Requests, boolean>
    },
  },
})

// Action creators are generated for each case reducer function
export const { addRequest, removeRequest } = loadingSlice.actions
export const { reducer } = loadingSlice
