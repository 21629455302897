import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IAddress, DeliveryMethod } from '../../../types/checkout'

export interface B2CCheckoutState {
  savedAddress: IAddress[]
  shipping: {
    shippingFee: number
    freeShippingMin: number
  }
  tax: number
  discount: {
    code: string
    amount: number
  }
  purchaseOrderNumber: string
  deliveryMethod: DeliveryMethod | undefined

  // ui states
  addNewAddressModalIsOpen: boolean
  editAddressModalIsOpen: boolean
  selectShippingMethodIsOpen: boolean
  addDeliveryNotesModalIsOpen: boolean

  taxExemptSubmissionResp: {
    status?: string
  }
}

const initialState: B2CCheckoutState = {
  savedAddress: [],
  shipping: {
    shippingFee: 30,
    freeShippingMin: 100,
  },
  tax: 10,
  discount: {
    code: '',
    amount: 0,
  },
  purchaseOrderNumber: '',
  deliveryMethod: undefined,

  // ui states
  addNewAddressModalIsOpen: false,
  editAddressModalIsOpen: false,
  selectShippingMethodIsOpen: false,
  addDeliveryNotesModalIsOpen: false,

  taxExemptSubmissionResp: {},
}

export const b2cCheckoutSlice = createSlice({
  name: 'b2cCheckoutStore',
  initialState,
  reducers: {
    // address operations
    addAddress: (state, { payload }: PayloadAction<IAddress>) => {
      state.savedAddress = [
        ...(state.savedAddress || []),
        {
          ...payload,
          id: ((state.savedAddress?.length || 0) + 1).toString(),
        },
      ]
    },
    editAddress: (state, { payload }: PayloadAction<IAddress>) => {
      state.savedAddress = [
        ...state.savedAddress.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        }),
      ]
    },
    deleteAddress: (state, { payload }: PayloadAction<string>) => {
      if (state.savedAddress?.length > 0) {
        state.savedAddress = state.savedAddress.filter((address) => address.id !== payload)
      }
    },
    clearAddress: (state) => {
      state.savedAddress = []
    },

    // shipping operations
    setMinimumShippingAmount: (state, { payload }: PayloadAction<number>) => {
      state.shipping.freeShippingMin = payload
    },

    // discount operations
    addDiscount: (state, { payload }: PayloadAction<{ code: string; amount: number }>) => {
      state.discount = payload
    },

    // purchase order number operations
    addPurchseOrderNumber: (state, { payload }: PayloadAction<string>) => {
      state.purchaseOrderNumber = payload
    },
    clearPurchaseOrderNumber: (state) => {
      state.purchaseOrderNumber = ''
    },

    // delivery method operations
    setDeliveryMethod: (state, { payload }: PayloadAction<DeliveryMethod>) => {
      state.deliveryMethod = payload
    },
    clearDeliveryMethod: (state) => {
      state.deliveryMethod = undefined
    },

    // ui operations
    openAddNewAddressModal: (state) => {
      state.addNewAddressModalIsOpen = true
    },
    closeAddNewAddressModal: (state) => {
      state.addNewAddressModalIsOpen = false
    },
    openEditAddressModal: (state) => {
      state.editAddressModalIsOpen = true
    },
    closeEditAddressModal: (state) => {
      state.editAddressModalIsOpen = false
    },
    openSelectShippingMethodModal: (state) => {
      state.selectShippingMethodIsOpen = true
    },
    closeSelectShippingMethodModal: (state) => {
      state.selectShippingMethodIsOpen = false
    },
    openAddDeliveryNotesModal: (state) => {
      state.addDeliveryNotesModalIsOpen = true
    },
    closeAddDeliveryNotesModal: (state) => {
      state.addDeliveryNotesModalIsOpen = false
    },
    updateTaxExemptSubmissionResp: (state, { payload }: PayloadAction<string>) => {
      state.taxExemptSubmissionResp.status = payload
    },
    resetTaxExemptSubmissionResp: (state) => {
      delete state.taxExemptSubmissionResp.status
    },
  },
})

export const {
  // address
  addAddress,
  editAddress,
  deleteAddress,
  clearAddress,

  // shipping
  setMinimumShippingAmount,

  // discount
  addDiscount,

  // purchase order number
  addPurchseOrderNumber,
  clearPurchaseOrderNumber,

  // delivery method
  setDeliveryMethod,
  clearDeliveryMethod,

  // ui
  openAddNewAddressModal,
  closeAddNewAddressModal,
  openEditAddressModal,
  closeEditAddressModal,
  openSelectShippingMethodModal,
  closeSelectShippingMethodModal,
  openAddDeliveryNotesModal,
  closeAddDeliveryNotesModal,

  // API
  updateTaxExemptSubmissionResp,
  resetTaxExemptSubmissionResp,
} = b2cCheckoutSlice.actions
export const { reducer } = b2cCheckoutSlice
