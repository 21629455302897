const FileDownloadIcon = ({ fill = '#E11C22' }: { fill?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7_1179)">
      <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_7_1179">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export { FileDownloadIcon }
