import { useEffect, useRef } from 'react'
// import { useNavigate } from 'react-router-dom'

import EmployeeRoutes from '../routes/EmployeeRoutes'

import { useAuth } from '../contexts/auth.context'

import { useAppDispatch } from '../hooks/reduxHooks'
import { setUserInfo, setRole, setLogoutInProgress } from '../stores/AuthStore/authSlice'

import { parseJwt, isValidJwt } from '../utils/common'
import { trackUserLogin } from '../utils/segment'
import { UserRole } from '../types/user'

type Props = {
  token: string
}

const EmployeeMainContent = ({ token }: Props) => {
  // const navigate = useNavigate()

  const { setToken } = useAuth()
  const dispatch = useAppDispatch()

  const loadedRef = useRef(true)

  useEffect(() => {
    if (token && !isValidJwt(token)) {
      dispatch(setRole(null))
      dispatch(setLogoutInProgress(true))
      window.localStorage.removeItem('token')
      window.location.href = 'https://berlin.okta.com'
    } else {
      const jwtPayload = parseJwt(token)

      setToken(token)
      window.localStorage.setItem('token', token)

      dispatch(setRole(UserRole.EMPLOYEE))
      dispatch(
        setUserInfo({
          firstName: jwtPayload.given_name ?? '',
          lastName: jwtPayload.family_name ?? '',
          email: jwtPayload.email ?? '',
          newUser: false,
          userId: jwtPayload.sub ?? '',
          userRoles: jwtPayload.roles ?? [],
        })
      )

      // segment tracking
      const email = jwtPayload?.email
      const userId = jwtPayload?.sub
      if (email && userId) {
        trackUserLogin(userId, email)
      }

      // navigate('/')
    }
    loadedRef.current = true
  }, [token, setToken, dispatch])

  return <div className="App employee-portal">{loadedRef.current ? <EmployeeRoutes /> : null}</div>
}

export default EmployeeMainContent
