import { useRef, useState } from 'react'
import { Avatar as MuiAvatar, Box, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PhotoIcon } from '../../icons'
import { Button } from '../../common'
import { trackClick } from '../../../utils/segment'
import ImageCropper from '../ImageCropper'
import './../../../i18n'

type Props = {
  size: number
  image?: string
  hasUploadButton?: boolean
  onChange?: (url: string) => void
}

export const Avatar = ({ size, image, hasUploadButton, onChange }: Props) => {
  const { t } = useTranslation()
  const [file, setFile] = useState(image || '')
  const fileRef = useRef<HTMLInputElement>()
  const [openCropImage, setOpenCropImage] = useState(false)

  const handleClick = () => {
    fileRef.current?.click()
    // @analytics Track Upload Image button
    trackClick('On Boarding', {
      category: 'Click | Upload Image',
      label: 'Upload Image',
      location: window.location.href,
      page: 'On Boarding',
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files?.[0]) {
      setFile(URL.createObjectURL(e.target?.files?.[0]))
      setOpenCropImage(true)
    }
  }

  const handleSave = (croppedImage: string) => {
    setFile(croppedImage)
    setOpenCropImage(false)
    onChange?.(croppedImage)
  }

  return (
    <>
      <Box
        position="relative"
        width={size}
        height={size}
        sx={{
          ':hover': {
            '& .overlay': {
              display: 'flex',
            },
          },
        }}
      >
        <MuiAvatar
          src={file}
          style={{
            width: size,
            height: size,
          }}
        />
        <Box
          className="overlay"
          display="none"
          position="absolute"
          alignItems="center"
          justifyContent="center"
          borderRadius="100%"
          sx={{
            backgroundColor: '#00000060',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <IconButton onClick={handleClick}>
            <PhotoIcon />
          </IconButton>
        </Box>
        <Box
          component="input"
          ref={fileRef}
          accept="image/*"
          type="file"
          sx={{ visible: 'hidden', width: 0, height: 0 }}
          onChange={handleChange}
        />
      </Box>
      {hasUploadButton && (
        <Button sx={{ mt: 5, width: 250 }} label={t('button_upload_new_image')} variant="contained" onClick={handleClick} />
      )}
      {openCropImage && file && <ImageCropper image={file} onSave={handleSave} onClose={() => setOpenCropImage(false)} />}
    </>
  )
}
