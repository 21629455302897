// material-ui
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { PersonAddAlt } from '@mui/icons-material'

import { useAppSelector, useAppDispatch } from '../../../../hooks/reduxHooks'
import { selectRole } from '../../../../stores/AuthStore/selectors'
import { UserRole } from '../../../../types/user'
import { BoxIcon, CatalogIcon } from '../../../../components/icons'
import { setProductFilters } from '../../../../stores/ProductsStore/productsSlice'
import { isQorpak } from '../../../../utils/common'
import { ReactNode } from 'react'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const NavButton = ({ text, icon, onClick }: { text: string; icon: ReactNode; onClick: () => void }) => {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      startIcon={icon}
      sx={{
        p: '4px',
        color: theme.palette.common.black,
        '&:hover, &:focus': {
          background: 'none',
        },
        '.MuiButton-startIcon': {
          ml: 0,
          mr: '4px',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'none',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Typography>
    </Button>
  )
}

type Props = {
  shouldDisplayCatalog: boolean
  catalogHref?: string
}
const NavSection = ({ shouldDisplayCatalog = false, catalogHref }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const userRole = useAppSelector(selectRole)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      {userRole === UserRole.EMPLOYEE ? (
        <>
          <NavButton onClick={() => navigate('/')} icon={<BoxIcon />} text="Orders" />
          <NavButton
            onClick={() => navigate('/employee/user-management')}
            icon={<PersonAddAlt sx={{ color: theme.palette.common.black }} />}
            text="User Management"
          />
        </>
      ) : (
        <NavButton onClick={() => navigate('/orders')} icon={<BoxIcon />} text="Orders" />
      )}
      {shouldDisplayCatalog && (
        <NavButton
          onClick={() => {
            // clear filters first before navigating to prevent API being triggered twice
            dispatch(setProductFilters({ filters: {}, searchString: '', page: 1, perPage: 12, sortBy: 'NONE' }))
            if (catalogHref && location.pathname !== catalogHref) {
              isQorpak() ? window.open(catalogHref, '_blank') : navigate(catalogHref)
            }
          }}
          icon={<CatalogIcon />}
          text="My Products"
        />
      )}
    </Box>
  )
}

export default NavSection
